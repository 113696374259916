import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/webdevelopment.png"
import imgg1 from "../../src/assets/images/21571914465.jpg"


const WebDevelopment= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
      
      
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Web Development" style={{marginTop:"20px"}}>Web Development</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
       <div className="container mt-5">

      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-4 col-lg-4">
            <img src={imgg1} alt="WebDevelopment" className="img-fluid"/>

        </div>
        <div className="col-sm-12 col-md-8 col-xl-8 col-lg-8">
          <h3>Delivering Perfect Web Development Solutions…</h3>
          <p style={{textAlign:"justify"}}>Signin Soft is highly regarded as the premier Web Development Services Company, boasting an accomplished team of web developers. Our pool of skilled full-stack web designers and developers possess extensive knowledge, enabling them to craft top-tier web solutions, web applications, and web portals. We prioritize delivering exceptional user experiences and tailor-made solutions that align with the specific requirements of your industry. With a track record of successfully completing 23+ web projects across diverse business domains, our dedicated web developers excel in creating unparalleled and compelling functionalities. Our approach seamlessly blends the best attributes of both startup and enterprise business models, resulting in incomparable outcomes. As a distinctive web development services provider based in India, the following facts substantiate our claims.</p>
        </div>
      </div>
    </div>
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default WebDevelopment