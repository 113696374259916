import React ,{useEffect} from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import Card from "react-animated-3d-card";
 import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from 'react-router-dom';
import image from "../../src/assets/images/visa.png"
import imgg1 from "../../src/assets/images/101571829726.jpg"
import vimgg1 from "../../src/assets/images/1.png"
import vimgg2 from "../../src/assets/images/v2.jpg"
import vimgg3 from "../../src/assets/images/v31571827007.jpg"
import vimgg4 from "../../src/assets/images/v41571827311.jpg"
import vimgg5 from "../../src/assets/images/v51571828272.jpg"
import vimgg6 from "../../src/assets/images/v61571828350.jpg"
import vimgg7 from "../../src/assets/images/v71571828430.jpg"
import vimgg8 from "../../src/assets/images/v81571828962.jpg"
import vimgg9 from "../../src/assets/images/v91571895914.jpg"
import vimgg10 from "../../src/assets/images/v101571897499.jpg"
import vimgg11 from "../../src/assets/images/v111571899409.jpg"
import vimgg12 from "../../src/assets/images/v121571899650.jpg"
import vimgg13 from "../../src/assets/images/v131571900366.jpg"
import vimgg14 from "../../src/assets/images/v141571902072.jpg"



const Visa = () => {
 
  

    const cards = [
        {
          title: "Australia",
          listItems: "From Rs - 8000/-",
          imgSrc: vimgg1,
          route: "/Australia"
        },
        {
          title: "Dubai",
          listItems: "From Rs - 8000/-",
          imgSrc: vimgg2,
          route: "/Dubai"
        },
        {
          title: "Thailand",
          listItems: "From Rs - 5000/-",
          imgSrc: vimgg3,
          route: "/Thailand",
        },
        {
          title: "Singapore",
          listItems: "From Rs - 4000/-",
          imgSrc: vimgg4,
          route: "/Singapore",
        },
      ];

      
      
    const cards1 = [
      {
        title: "Malaysia",
        listItems: "From Rs - 5000/-",
        imgSrc: vimgg5,
        route: "/Malaysia",
      },
      {
        title: "Srilanka",
        listItems: "From Rs - 3000/-",
        imgSrc: vimgg6,
        route: "/Srilanka",
      },
      {
        title: "Cambodia",
        listItems: "From Rs - 2500/-",
        imgSrc: vimgg7,
        route: "/Cambodia",
      },
      {
        title: "Italy",
        listItems: "From Rs - 3500/-",
        imgSrc: vimgg14,
        route: "/Italy",

      },
     
    ];
    const cards2 = [
      {
        title: "USA",
        listItems: "From Rs - 5000/-",
        imgSrc: vimgg9,
        route: "/USA",

      },
      {
        title: "Switzerland",
        listItems: "From Rs - 8000/-",
        imgSrc: vimgg10,
        route: "/Switzerland",

      },
      
      {
        title: "Schengen",
        listItems: "From Rs - 8000/-",
        imgSrc:vimgg12,
        route: "/Schengen",

      },
      {
        title: "Germany",
        listItems: "From Rs - 3500/-",
        imgSrc: vimgg13,
        route: "/Germany",

      },
    ];
    
  return (
    <div>
        <Topnav/>
       <Header/>
       
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Visaservices"  style={{marginTop:"20px"}}>Visas</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>

       <div className="container mt-5">

      <div className="row">
        <div className="col-sm-12 col-md-4 col-xl-4 col-lg-4">
            <img src={imgg1}   alt="Visa img"className="img-fluid"/>

        </div>
        <div className="col-sm-12 col-md-8 col-xl-8 col-lg-8">
          <h3>Travelling Overseas Made Easy @ Signin Soft</h3>
          <p style={{textAlign:"justify"}}>At Signin Soft, we provide world className Visa / Immigration Services in Hyderabad. As a renowned Visa Services & Immigration company, we help individuals in securing Tourist visas, visitor visas, and business visas. With a team of skilled professional and expert immigration counselors, we provide dedicated and timely service to our customers, thereby standing as one of the successful Visa Service Providers in India.

            We offer the most welcoming atmosphere for our clients at our offices.You can walk-in any time if you are looking for any assistance or answer to any of your queries. Our offices always stay updated with the changes in immigration and visa rules. Our brand’s reputation helps us to serve you towards your settlement needs in your destination country.
            
            We excel in processing Visas and related documents for Tourists, Corporate Travelers, Travel Agents, Groups, Students, Government Employees and Diplomats and even International Humanitarian organizations. Not just this, we also help you in obtaining the necessary documents, so you never miss a trip because of incomplete or inaccurate paperwork.</p>
        </div>
      </div>
    </div>
<hr/>

<div className="container mt-5">
      <div className="row">
        {cards.map((item, index) => (
          <div
            className="col-sm-12 col-lg-3 col-xl-3 col-md-12"
            key={index}
          >
            <Link to={item.route}  target="_top">
            <Card
              style={{
                width: "300px",
                height: "100%",
                cursor: "pointer",
                boxShadow: "none",
                transition: "transform 0.5s",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                border: "none",
                display: "flex",
                flexDirection: "column",
                marginTop:'20px'
              }}
              className="card-3d"
            >
              
                            <div className="card shadow-sm p-3 text-center" style={{ borderRadius: "15px", borderBottom: "4px solid orange" }}>
              <CardContent style={{ flex: 1 }}>

                <div className="text-center">
                  <img src={item.imgSrc} className="img-fluid c-img" alt={item.title} />
                
                </div>
                <Typography gutterBottom variant="h5" component="div">
                 <Link to={item.route}  target="_top"> <h5 className="mt-2">{item.title}</h5></Link>

                </Typography>
                <Link to={item.route}  target="_top">  <div className="button-primary-text">{item.listItems}</div></Link>
              
              </CardContent>
              </div>
            </Card>
           </Link>
          </div>
        ))}
      </div>
    </div>
    <div className="container mt-5">
      <div className="row">
        {cards1.map((item, index) => (
          <div
            className="col-sm-12 col-lg-3 col-xl-3 col-md-12"
            key={index}
          >
             <Link to={item.route}  target="_top">
            <Card
              style={{
                width: "300px",
                height: "100%",
                cursor: "pointer",
                boxShadow: "none",
                transition: "transform 0.5s",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                border: "none",
                display: "flex",
                flexDirection: "column",
                marginTop:'20px'
              }}
              className="card-3d"
            >
              
                            <div className="card shadow-sm p-3 text-center" style={{ borderRadius: "15px", borderBottom: "4px solid orange" }}>
              <CardContent style={{ flex: 1 }}>
                <div className="text-center">
                  <img src={item.imgSrc} className="img-fluid c-img" alt={item.title} />
                
                </div>
                <Typography gutterBottom variant="h5" component="div">
                 <h5 className="mt-2">{item.title}</h5>

                </Typography>
                <div className="button-primary-text">{item.listItems}</div>
              
              </CardContent>
              </div>
            </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
    <div className="container mt-5 mb-5">
      <div className="row">
        {cards2.map((item, index) => (
          <div
            className="col-sm-12 col-lg-3 col-xl-3 col-md-12"
            key={index}
          >
            <Link to={item.route}  target="_top">
            <Card
              style={{
                width: "300px",
                height: "100%",
                cursor: "pointer",
                boxShadow: "none",
                transition: "transform 0.5s",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                border: "none",
                display: "flex",
                flexDirection: "column",
                marginTop:'20px'
              }}
              className="card-3d"
            >
              
                            <div className="card shadow-sm p-3 text-center" style={{ borderRadius: "15px", borderBottom: "4px solid orange" }}>
              <CardContent style={{ flex: 1 }}>
                <div className="text-center">
                  <img src={item.imgSrc} className="img-fluid c-img" alt={item.title} />
                
                </div>
                <Typography gutterBottom variant="h5" component="div">
                 <h5 className="mt-2">{item.title}</h5>

                </Typography>
                <div className="button-primary-text">{item.listItems}</div>
              
              </CardContent>
              </div>
            </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
    {/* <div className="container mt-5 mb-5">
      <div className="row">
        {cards3.map((item, index) => (
          <div
            className="col-sm-12 col-lg-3 col-xl-3 col-md-12"
            key={index}
          >
                         <Link to={item.route}  target="_top">

            <Card
              style={{
                width: "300px",
                height: "100%",
                cursor: "pointer",
                boxShadow: "none",
                transition: "transform 0.5s",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                border: "none",
                display: "flex",
                flexDirection: "column",
                marginTop:'20px'
              }}
              className="card-3d"
            >
              
                            <div className="card shadow-sm p-3 text-center" style={{ borderRadius: "15px", borderBottom: "4px solid orange" }}>
              <CardContent style={{ flex: 1 }}>
                <div className="text-center">
                  <img src={item.imgSrc} className="img-fluid c-img" alt={item.title} />
                
                </div>
                <Typography gutterBottom variant="h5" component="div">
                 <h5 className="mt-2">{item.title}</h5>

                </Typography>
                <div className="button-primary-text">{item.listItems}</div>
              
              </CardContent>
              </div>
            </Card>
           </Link>
          </div>
        ))}
      </div>
    </div> */}
   
<Footer/>
    </div>
  )
}

export default Visa