import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Italy= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
      
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Italy Visa" style={{marginTop:"20px"}}>Italy Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <p>Italy Visa(Procesing Fee: 3500 INR)</p>
<br/>

 
      <h5>Documents required for Italy visa application </h5>


<ul  className='p-3'>
<li>The applicant must submit his/her original passport with validity of a minimum of three months and a minimum of two blank pages for visa stamps.<br/> Along with the original passport, he/she must submit a copy of the first page and last page of the passport.</li>
      <li> <b>Please note:</b> Passports issued beyond 10 years will not be accepted by the Embassy.</li>
<li>Attach all your old passports (if any).</li>
<li>Handwritten passports are not accepted.</li>
<li>One visa application forms need to be duly filled and signed by the applicant.</li>
      <li><b>Please note:</b> The applicant’s mobile number should be mentioned in the visa form as Embassy / Consulate can contact the applicant at any point of time during visa processing.</li>
<li>Two recent passport-size coloured photographs with matte or semi-matte finish, 80% face coverage, white background and without border (Size: 35mm x 45mm).</li>
<li>Please note that people who live in Chennai will have to provide photographs with different specifications:Two recent passport size coloured photographs with glossy finish, 80% face coverage, white background and without border (Size: 35mm x 45mm)</li>
      <li> <b>Please note:</b> Photograph should not be more than 3 months old, scanned / stapled and should not be used in any of the previous visas.</li>
     <li>A covering letter has to be submitted by the applicant on business letter head mentioning name, designation, passport number, purpose and duration of visit in brief. The letter should be duly signed by authorised signatory with company stamp and addressed to 'The Consulate General of Italy, Mumbai'.</li>
     <li>The letter should be duly signed by an authorized signatory with a company stamp and addressed to 'The Consulate General of Italy, Mumbai'.</li>
  <li>As proof of the applicant's financial status, the below documents have to be submitted:
   
      <li>Personal ITR for the last 3 years</li>
      <li>Personal Bank Statement for the last six months</li>
      <li>Salary slips for the last three months</li>
      <li>Credit Card copy with recent statements OR Original Foreign Exchange endorsement with receipt OR traveler’s cheque</li>
    
  </li>
  <li>As proof of occupation, the below documents have to be submitted by the applicant in the following scenarios:</li>
    
      <li>If the applicant is self-employed:</li>
      
        <li>Company Registration Certificate / Certificate of Incorporation / Proof of Proprietorship / Partnership Deed</li>
        <li>Brief Company Profile</li>
        <li>Articles of Memorandum (in case the applicant is MD / Director)</li>
      
</ul>

<h5>If the applicant is employed in a firm:</h5>
<ul  className='p-3'>
<li>Leave letter and No Objection Certificate from employer in original</li>
    
</ul>
    
<h5>If the applicant is a student: </h5>
<ul  className='p-3'>
<li>Leave sanction letter, school ID card and NOC from school mentioning the dates of trip</li></ul>

<h5>If you are under 18, you must also submit:</h5>
<ul  className='p-3'>
  <li>Application letter which must be signed by both the parents or legal guardian.</li>
  <li>No Objection Affidavit from parents to be provided in both cases - travelling with single parent or both.</li>
  <li>The applicant has to submit a notarized and legalized No Objection Certificate (NOC) for a minor on a stamp paper of Rs 100/- if he/she is traveling without/with one parent.</li>
  <li>The applicant must submit his/her hotel confirmation for the entire period of stay in the Schengen area as proof of accommodation.</li>
  <li>Please Note: The hotel accommodation proof should match the ticket dates. In case the applicant is invited by a relative or friend residing in Italy, a letter of invitation from those living in Italy must be submitted.</li>
  <li>A copy of the applicant’s air ticket showing his/her ticket Itinerary clearly.</li>
  <li>Please Note: The travel date mentioned on the ticket itinerary should be a minimum of 10 days after the submission of the application.</li>
  <li>An original Medical Insurance for the stay duration amounting to 30,000 Euros duly signed and stamped by the insurance company. (Please Note: The policy should be for a minimum of 15 days.)</li>
</ul>

<h5>Please Note:</h5>
<ul  className='p-3'>
<li>Insurance has to be issued by the Authorized Insurance Companies.</li>
<li>Minimum 10 days insurance is mandatory from the travel date.</li>
<li>For those residing in Mumbai, a proof of residence is required only for those applicants who are not residents of the Mumbai Jurisdiction.</li>
<li><b>Please Note:</b>The submitted proof of residence should show a one year residence period to be qualified as proof while applying for an Italy Visa from the West and South region of India.</li>
<li>Mandatory documents for Italy visa:</li>
<li>Letter on company letterhead specifying the period he/she has been working with them.</li>
<li>Lease agreement (letter from company, if accommodation provided by the company).</li>
<li>Bank statement and letter from bank specifying the period since when the account is operating.</li>
<li>Electricity Bill, Telephone Bill and Gas Bills.</li>
<li>Additional documents for Italy visa:</li>
<li>Pan Card and Income tax challans or Form 16A.</li>
<li>Ration card or Election card or Aadhaar card.</li>
<li>The Applicant will have to submit an authorization letter authorizing submit and collect documents.</li>
<li><b>Important note:</b> With immediate effect, Indian citizens travelling to Schengen countries for short-term (max 90 days in 180 days) will have to visit the visa application centre personally to provide biometric data (fingerprints and digital photograph).</li>
</ul>

  </div>
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Italy