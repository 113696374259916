import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Srilanka= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
      
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Srilanka Visa" style={{marginTop:"20px"}}>Srilanka Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <p>30days Evisa – 3000/- (Processing Time: Within 30 minutes)</p>
      

  <h5>Documents required:</h5>
 
  <ul  className='p-3'>
    <li>Scanned color copy of the first and last page of your passport.</li>
<li>Passport should be valid for at least 6 months.</li>
<li>Flight Tickets copy and Hotel accommodation/Stay Details in Srilanka.</li>
  </ul>
    
 
 
  </div>  
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Srilanka