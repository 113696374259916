import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Thailand= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Thailand Visa" style={{marginTop:"20px"}}>Thailand Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <p>Tourist Visa Single entry(30 days)-5,000 INR incl all charges</p>
  <h5>Documents required for Thailand Visa application</h5>
  <ul  className='p-3'>
    <li>Original passport and old passports, if any</li>
    <li>Passport should be valid for at least 6 months from the date of return, with a minimum of two blank pages</li>
    <li>Two copies of the first and last page of your passport duly attested</li>
    <li>Confirmed return air tickets</li>
    <li>Two visa application form duly filled and signed by the applicant</li>
  <li>2 recent passport-size colour photographs taken against a white background, size (35 mm x 45 mm with 80% face close up)</li>
    
  <li>Confirmation of hotel with address and number</li>
  <li>Original bank statements for the last 6 months reflecting a minimum balance of Rs 52,000, that has been attested by a bank official</li>
  <li>Evidence of occupation and of the monthly income: Income Tax Returns for the last 3 years, Payslips, a letter from employer certifying employment, Employee ID, Student ID, Business license.</li>
</ul>
  <h5>Additional documents</h5>

  <h5>If the applicant is employed</h5>
  <ul  className='p-3'>
    <li>A cover letter from both company and travel agency stating the purpose of travel and traveler details.</li>
  </ul>
    
  <h5>If the applicant has a Friend / Family in Thailand</h5>
  <ul  className='p-3'>
    <li>If you intend to stay with Thai relatives or friends, invitation letter and ID proof of the invitee is mandatory.</li>
    <li>If the invitee is not a Thai citizen, copies of work visa, work permit and passport is mandatory.</li>
  </ul>
 <p><b>Processing Time: 7-8 working days.</b></p>
 
  </div>  
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Thailand