import React ,{useState,useEffect} from 'react'
import Header from './header'
import Footer from './footer'
import Topnav from './Topnav'
import Card from "react-animated-3d-card";
 import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import image from "../src/assets/images/careers.png"


const Careerjobs = () => {
  const [selectedJob, setSelectedJob] = useState(null);

  const showJobInfo = (jobTitle) => {
    setSelectedJob(jobTitle);
  };

  useEffect(() => {
    const applyMediaStyles = () => {
      const elements = document.querySelectorAll('.parallax-card-layers');

      elements.forEach(element => {
        const width = window.innerWidth;
        if (width >= 280 && width <= 653) {
          element.style.width = '220px'; 
        } else {
          element.style.width = '340px'; 
        }
      });
    };

    window.addEventListener('resize', applyMediaStyles);
    applyMediaStyles(); 

    return () => {
      window.removeEventListener('resize', applyMediaStyles);
    };
  }, [selectedJob]);

  const cards = [
    {
        title: "Work Authorization",
        listItems: (
          <ul class="liststylesmanage">
          <li><i class="fa fa-close"></i>US Citizen</li>
          <li><i class="fa fa-close"></i>GC</li>
          <li><i class="fa fa-close"></i>H1B</li>
          <li><i class="fa fa-close"></i>EAD <br/>(OPT/CPT/GC/H4)</li>
      </ul>)
    },
    {
        title: "Preferred Employment",
        listItems: (<ul class="liststylesmanage">
        <li><i class="fa fa-close"></i>Full-Time</li>
        <li><i class="fa fa-close"></i>Contract</li>
        <li><i class="fa fa-close"></i>Remote</li>
    </ul>)
    },
    {
        title: "Job Details",
        listItems: (<ul class="liststylesmanage">
        <li>Experience: Senior</li>
        <li>Salary ($): $100,000 - $120,000 per year</li>
        <li>Location:Atlanta, GA</li>
        <li>Industry: Technology</li>
    </ul>)
    }
];

const cards1 = [
  {
      title: "Work Authorization",
      listItems: (
        <ul class="liststylesmanage">
          <li><i class="fa fa-close"></i>US Citizen</li>
          <li><i class="fa fa-close"></i>GC</li>
          <li><i class="fa fa-close"></i>H1B</li>
          <li><i class="fa fa-close"></i>EAD <br/>(OPT/CPT/GC/H4)</li>
          
        </ul>
    )
  },
  {
      title: "Preferred Employment",
      listItems: (<ul class="liststylesmanage">
      <li><i class="fa fa-close"></i>Corp-Corp</li>
      <li><i class="fa fa-close"></i>W2-Permanent</li>
      <li><i class="fa fa-close"></i>1099-Contract</li>
      <li><i class="fa fa-close"></i>Contract to Hire</li>
      
    </ul>)
  },
  {
      title: "Job Details",
      listItems: (<ul class="liststylesmanage">
      <li>Experience: Architect</li>
      <li>Rate/Salary ($): $60 - $65 hr</li>
      <li>Location:North Hills, GA</li>
      <li>Sp. Area: JS, Front End, UI, UX, Web, CMS,....</li>
      
    </ul>)
  }
];
const cards2 = [
  {
      title: "Work Authorization",
      listItems: (
        <ul class="liststylesmanage">
        <li><i class="fa fa-close"></i>US Citizen</li>
        <li><i class="fa fa-close"></i>GC</li>
        <li><i class="fa fa-close"></i>H1B</li>
        <li><i class="fa fa-close"></i>EAD <br/>(OPT/CPT/GC/H4)</li>
    </ul>
    )
  },
  {
      title: "Preferred Employment",
      listItems: (<ul class="liststylesmanage">
      <li><i class="fa fa-close"></i>Full-Time</li>
      <li><i class="fa fa-close"></i>Contract</li>
      <li><i class="fa fa-close"></i>Remote</li>
  </ul>)
  },
  {
      title: "Job Details",
      listItems: (<ul class="liststylesmanage">
      <li>Experience: Senior</li>
      <li>Salary ($): $120,000 - $140,000 per year</li>
      <li>Location:North Hills, GA</li>
      <li>Industry: Cybersecurity</li>
  </ul>)
  }
];
const cards3 = [
  {
      title: "Work Authorization",
      listItems: (
        <ul class="liststylesmanage">
                  <li><i class="fa fa-close"></i>US Citizen</li>
                  <li><i class="fa fa-close"></i>GC</li>
                  <li><i class="fa fa-close"></i>H1B</li>
                  <li><i class="fa fa-close"></i>EAD <br/>(OPT/CPT/GC/H4)</li>
              </ul>
    )
  },
  {
      title: "Preferred Employment",
      listItems: (<ul class="liststylesmanage">
      <li><i class="fa fa-close"></i>Full-Time</li>
      <li><i class="fa fa-close"></i>Contract</li>
      <li><i class="fa fa-close"></i>Remote</li>
  </ul>)
  },
  {
      title: "Job Details",
      listItems: (<ul class="liststylesmanage">
      <li>Experience: Mid-Level</li>
      <li>Salary ($): $80,000 - $90,000 per year</li>
      <li>Location:Austin, TX</li>
      <li>Sp. Area: Software Development, Algorithms, Data Structures, API Design</li>
  </ul>)
  }
];  

  return (
    <div>
      <Topnav/>
       <Header/>
       <section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Web Development" style={{marginTop:"20px"}}>Careers</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2  img-fluid"/></div>
  </div>
  </div>
</section>
       <div className="container mt-3" style={{overflowX:"auto"}}>

<table className="table" id="customers">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Date</th>
      <th scope="col">Time</th>
      <th scope="col">Title</th>
      <th scope="col">Location</th>
      <th scope="col">Apply</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Feb18</td>
      <td>08:39 AM</td>
      <td>Senior Product Owner</td>
      <td>	Atlanta, GA</td>
      <td><button type="button" className="btn btn-primary" onClick={() => showJobInfo('Senior Product Owner')}>Apply</button></td>

    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Feb18</td>
      <td>08:38 AM</td>
      <td>Senior Software Engineer</td>
      <td>	North Hills, GA</td>
      <td><button type="button" className="btn btn-primary" onClick={() => showJobInfo('Senior Software Engineer')}>Apply</button></td>

    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Feb18</td>
      <td>08:38 AM</td>
      <td>Senior DevSecOps Cybersecurity</td>
      <td>North Hills, GA</td>
      <td><button type="button" className="btn btn-primary" onClick={() => showJobInfo('Senior DevSecOps Cybersecurity')}>Apply</button></td>
    </tr>
    <tr>
      <th scope="row">4</th>
      <td>Feb18</td>
      <td>08:38 AM</td>
      <td>Software Engineer II</td>
      <td>Austin, TX</td>
      <td><button type="button" className="btn btn-primary" onClick={() => showJobInfo('Software Engineer II')}>Apply</button></td>
    </tr>
  </tbody>
</table>
</div>

{selectedJob === 'Senior Product Owner' && (   
  <div class="container mt-5">
  <h1  alt="Signin Soft Senior Product Owner"  style={{color:"black"}}>Senior Product Owner</h1>
     
 <div className="row mt-5">
            <Grid container spacing={4}>
                {cards.map((item, index) => (
                    <Grid item xs={12} xl={4} lg={4} sm={12} md={6} key={index}>
                        <Card  
                            style={{
                                width: "340px",
                                height: "200px",
                                cursor: "pointer",
                                boxShadow: "none",
                                transition: "transform 0.5s",
                                transformStyle: "preserve-3d",
                                backfaceVisibility: "hidden",
                                border: "none",
                                display: "flex",
                                flexDirection: "column"
                            }}
                            className="card-3d"
                        >
                            <CardContent style={{ flex: 1 }}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={{ flex: 1 }}>
                                    {item.listItems}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
  <h6 class="mt-4"> <i class="fa fa-check"></i>Full-Time</h6>
  <div class="card shadow-sm p-4 h-100 mt-4 required">
      <p><b>Required Skills:</b> Agile, Scrum, Product Management, User Stories, Stakeholder Management, Roadmap Planning, JIRA, Confluence, Market Analysis, Problem-Solving</p>
  </div>
  
  <div class="container mt-5">
      <div class="card shadow-sm p-4 border-0">
          <p><b>Preferred Skills:</b><br/>
          " Experience in product strategy development and execution."<br/>
          " Familiarity with user-centered design principles."<br/>
          " Market research and trend analysis skills."<br/>
          " Excellent communication and presentation skills."<br/>
          " Previous experience in technology or software product domains."<br/>
              <b>Domain:</b> Product Management</p>
          <b class="mt-3" >Job Description:</b>
          <div>
              Title: Senior Product Owner<br/>
              Location: Metrotech, Brooklyn, NY (onsite)<br/>
              Duration:  6+ months contract <br/>
              Visa:  USC, GC only <br/>
              Pay Rate:   60-65/hr  <br/>
              Client:  Dept of Technologies and Innovation  <br/>

              
              We are seeking an experienced Senior Product Owner to lead the development and execution of our product strategy.<br/>
              The ideal candidate will work closely with cross-functional teams to deliver high-quality products that meet customer needs.<br/>
              Define and prioritize product features and enhancements based on market analysis and customer feedback.<br/>
              Create and manage the product roadmap, ensuring alignment with company goals.<br/>
              Collaborate with development teams to deliver features on time and within scope.<br/>
              Gather and prioritize user stories, maintaining a well-defined backlog.<br/>
              Communicate effectively with stakeholders, including internal teams and external clients.<br/>
              Conduct regular market research and competitor analysis to identify trends and opportunities.<br/>
              Lead and facilitate agile ceremonies, such as sprint planning and backlog grooming.<br/>
              Continuously iterate on the product based on user feedback and data-driven insights.<br/>
              </div>
              <div class="mt-4" >
  <b>MANDATORY SKILLS:</b><br/>
              "Minimum of 5 years of experience in product management or related roles."<br/>
              "Proficiency in Agile methodologies and Scrum framework."<br/>
              "Strong communication and collaboration skills."<br/>
              "Experience using JIRA and Confluence for project management."<br/>
              "Ability to create and maintain a product roadmap."<br/>
              "Demonstrated experience in leading cross-functional teams."<br/>
              </div>
<div class="mt-4">
 <b> DESIRABLE SKILLS/EXPERIENCE:</b><br/>
              "Previous experience in a senior or leadership role."<br/>
              "Background in technology or software products."<br/>
              "Familiarity with market analysis and competitive intelligence."<br/>
              "Experience with user-centered design principles."<br/>
              
              <h6 class="mt-4 client">Client: InnovateTech Solutions, Inc.</h6>
          </div>
      </div>
  </div>
</div>

)}
{selectedJob === 'Senior Software Engineer' && (
  <div class="container mt-5">
    <h1 alt="Signin Soft Senior Software Engineer" style={{color:"black"}}>Senior Software Engineer</h1>
    <div className="row mt-5">
            <Grid container spacing={4}>
                {cards1.map((item, index) => (
                    <Grid item  xs={12} xl={4} lg={4} sm={12} md={6} key={index}>
                        <Card
                            style={{
                                width: "340px",
                                height: "200px",
                                cursor: "pointer",
                                boxShadow: "none",
                                transition: "transform 0.5s",
                                transformStyle: "preserve-3d",
                                backfaceVisibility: "hidden",
                                border: "none",
                                display: "flex",
                                flexDirection: "column"
                            }}
                            className="card-3d"
                        >
                            <CardContent style={{ flex: 1 }}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={{ flex: 1 }}>
                                    {item.listItems}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
  
  <h6 class="mt-4"> <i class="fa fa-check"></i>Consulting / Contract</h6>
  <div class="card  shadow-sm p-4 h-100 mt-4 required">
    <p><b>Required Skills :</b> Agile, GitHub, Azure DevOps, JIRA, Trello, Kanban, WordPress, PHP, Composer, Packagist, SQL, JavaScript, CSS, NPM, AWS, EC2, ELB, Docker hub, S3, RDS,
    </p>
    </div>
   
    <div class="container mt-5">
    
<div class="card shadow-sm p-4 border-0">
  <p> <b>Preferred Skills :</b><br/>
  " Strong experience with architecting and designing complex software systems."<br/>
    " Familiarity with various software development methodologies (Agile, Scrum, etc.)."<br/>
    " Proven track record of delivering high-quality code on schedule."<br/>
    " Ability to mentor and lead junior developers in best practices."<br/>
    " Expertise in software architecture patterns and design principles."<br/>
    <b>Domain :</b> IT/Software</p>
  <b class="mt-3" >Job Description :</b>
  <div>
  Title:Senior Software Engineer <br/>
Location:  Metrotech, Brooklyn, NY (onsite)  <br/>
Duration:  6+ months contract <br/>
Visa:  USC, GC only <br/>
Pay Rate:   60-65/hr  <br/>
Client:  Dept of Technologies and Innovation  <br/>


Senior Software Developer with Strong front end / UI engineering experience. <br/>
ONLY ESTERN Candidates from NJ-NY-PA-CT. <br/>
SCOPE OF SERVICES. <br/>
The Software Developer will work with City software developers to build and test software related to My City and NYC Opportunity's. <br/>
Work will be done in agile sprints, with work being assigned from a prioritized backlog. <br/>
All developers will conduct code reviews, and have their code reviewed. <br/>
All code should be tested where appropriate, as agreed with the city engineering leads. <br/>
</div>
<div class="mt-4" >
  <b>MANDATORY SKILLS:</b><br/>

" Minimum of 8+ years of experience writing software in the technologies listed below, specific to the solicited role."<br/>
" Experience working with Git-based workflows, and specifically GitHub."<br/>
" Experience working in story-based workflows, Azure DevOps, JIRA, Trello, or other story or Kanban style workflow."<br/>
Tech stack."<br/>
" Back end: WordPress, PHP, Composer, Packagist, SQL."<br/>
" Front end: JavaScript, CSS, NPM."<br/>
" Cloud hosting environment: AWS, EC2, ELB, Docker hub, S3, RDS, Terraform."<br/>
" Git based version control in GitHub: Repository Management, GitHub Pipelines, Azure DevOps build environment."<br/>
" Strong front end / UI engineering experience."<br/>
</div>
<div class="mt-4">
 <b> DESIRABLE SKILLS/EXPERIENCE:</b><br/>
  " Experience building micro service-based products."<br/>
  " Experience writing developer focused API documentation."<br/>
  " Experience building and/or architecting large complex systems."<br/>
  " Experience integrating design system libraries."<br/>
  " Experience working with internationalization approaches and implementation, particularly the WordPress Multilingual Plugin (WPML)."<br/>
  " Experience working with WCAG AA/508 accessibility standards."<br/>
<h6 class="mt-4 client">Client : RIT Solutions, Inc.</h6>
                
</div>
</div>
    </div>

</div>)}
{selectedJob === 'Senior DevSecOps Cybersecurity' && (
  <div class="container mt-5">
  <h1 alt="Signin Soft Senior DevSecOps" style={{color:"black"}}>Senior DevSecOps Cybersecurity</h1>
  <div className="row mt-5">
            <Grid container spacing={4}>
                {cards2.map((item, index) => (
                    <Grid item  xs={12} xl={4} lg={4} sm={12} md={6} key={index}>
                        <Card
                            style={{
                                width: "340px",
                                height: "200px",
                                cursor: "pointer",
                                boxShadow: "none",
                                transition: "transform 0.5s",
                                transformStyle: "preserve-3d",
                                backfaceVisibility: "hidden",
                                border: "none",
                                display: "flex",
                                flexDirection: "column"
                            }}
                            className="card-3d"
                        >
                            <CardContent style={{ flex: 1 }}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={{ flex: 1 }}>
                                    {item.listItems}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
  
  <h6 class="mt-4"> <i class="fa fa-check"></i>Full-Time</h6>
  <div class="card shadow-sm p-4 h-100 mt-4 required">
      <p><b>Required Skills:</b> DevSecOps, Security Testing, Vulnerability Assessment, Continuous Integration/Continuous Deployment (CI/CD), Docker, Kubernetes, Penetration Testing, Threat Modeling</p>
  </div>
  
  <div class="container mt-5">
      <div class="card shadow-sm p-4 border-0">
          <p><b>Preferred Skills:</b><br/>
                   " Experience in security architecture design and implementation."<br/>
                 " Familiarity with compliance audits and assessments."<br/>
                   " Knowledge of security frameworks such as NIST, ISO 27001."<br/>
                   " Strong understanding of encryption technologies."<br/>
                    " Ability to perform code review for security vulnerabilities."<br/>
                   " Excellent communication and collaboration skills."<br/>
               
              <b>Domain:</b> Cybersecurity</p>
          <b class="mt-3" >Job Description:</b>
          <div>
              Title: Senior DevSecOps Cybersecurity<br/>
              Location: Any<br/>
              Salary: $120,000 - $140,000 per year<br/>
              Industry: Cybersecurity<br/>
              
              We are seeking an experienced Senior DevSecOps professional to lead the cybersecurity efforts for our software products.<br/>
              Integrate security practices into the CI/CD pipeline for continuous security testing.<br/>
              Conduct security assessments and vulnerability assessments.<br/>
              Perform penetration testing and threat modeling.<br/>
              Implement security controls for Docker and Kubernetes environments.<br/>
              Collaborate with cross-functional teams to address security concerns.<br/>
              Develop and maintain security documentation and policies.<br/>
              Stay updated with the latest cybersecurity threats and best practices.<br/>
              Participate in incident response and mitigation efforts.<br/>
              </div>
              <div class="mt-4" >
  <b>MANDATORY SKILLS:</b><br/>
              "Minimum of 5 years of experience in DevSecOps or cybersecurity roles."<br/>
               "Proficiency in security testing methodologies and tools."<br/>
              "Experience with CI/CD pipelines and automation."<br/>
              "Knowledge of containerization technologies (Docker, Kubernetes)."<br/>
              "Strong understanding of threat landscape and risk management."<br/>
              </div>
              <div class="mt-4" >
  <b>MANDATORY SKILLS:</b><br/>
              "Previous experience in a senior or leadership role."<br/>
              "Background in cybersecurity practices for cloud environments."<br/>
              "Familiarity with compliance standards (e.g., GDPR, HIPAA)."<br/>
              "Certification in cybersecurity (e.g., CISSP, CEH)."<br/>
              
              <h6 class="mt-4 client">Client: SecureTech Solutions, Inc.</h6>
          </div>
      </div>
  </div>
</div>
)}
{selectedJob === 'Software Engineer II' && (
  <div class="container mt-5">
  <h1 alt="Signin Soft Software Engineer II" style={{color:"black"}}>Software Engineer II</h1>
  <div className="row mt-5">
            <Grid container spacing={4}>
                {cards3.map((item, index) => (
                    <Grid item  xs={12} xl={4} lg={4} sm={12} md={6} key={index}>
                        <Card
                            style={{
                                width: "340px",
                                height: "200px",
                                cursor: "pointer",
                                boxShadow: "none",
                                transition: "transform 0.5s",
                                transformStyle: "preserve-3d",
                                backfaceVisibility: "hidden",
                                border: "none",
                                display: "flex",
                                flexDirection: "column"
                            }}
                            className="card-3d"
                        >
                            <CardContent style={{ flex: 1 }}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={{ flex: 1 }}>
                                    {item.listItems}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
  
  <h6 class="mt-4"> <i class="fa fa-check"></i>Full-Time</h6>
  <div class="card shadow-sm p-4 h-100 mt-4 required">
      <p><b>Required Skills :</b> Java, Python, Git, Software Development Life Cycle (SDLC), Agile, Unit Testing, Problem-Solving</p>
  </div>
  
  <div class="container mt-5">
      <div class="card shadow-sm p-4 border-0">
      <p><b>Preferred Skills:</b><br/>
                " Experience in product strategy development and execution."<br/>
                " Familiarity with user-centered design principles."<br/>
                " Market research and trend analysis skills."<br/>
                " Excellent communication and presentation skills."<br/>
                " Previous experience in technology or software product domains."<br/>
              <b>Domain:</b> IT/Software</p>
          <b class="mt-3" >Job Description:</b>
          <div>
              Title: Software Engineer II<br/>
              Location: Any<br/>
              Salary: $80,000 - $90,000 per year<br/>
              
              We are seeking a Software Engineer II to join our dynamic team.<br/> 
              Design, code, and test software applications in Java and Python.<br/>
              Collaborate with product managers and fellow engineers to develop and enhance features.<br/>
              Contribute to the design and architecture of systems, focusing on scalability and maintainability.<br/>
              Participate in code reviews and provide constructive feedback.<br/>
              Follow Agile methodologies and participate in sprint planning and retrospectives.<br/>
              </div>
              <div class="mt-4" >
  <b>MANDATORY SKILLS:</b><br/>
              "Minimum of 3 years of software development experience."<br/>
              "Proficiency in Java and Python programming languages."<br/>
              "Strong understanding of software development principles and SDLC."<br/>
              "Experience with Git version control and collaborative coding."<br/>
              "Familiarity with Agile methodologies and unit testing practices."<br/>
              </div>
<div class="mt-4">
 <b> DESIRABLE SKILLS/EXPERIENCE:</b><br/>
              "Experience with web technologies such as HTML, CSS, and JavaScript."<br/>
              "Knowledge of data structures, algorithms, and API design."<br/>
              "Previous work with cloud platforms like AWS or Azure."<br/>
              "Strong problem-solving and analytical skills."<br/>
              
              <h6 class="mt-4 client">Client: Tech Innovators Ltd.</h6>
          </div>
      </div>
  </div>
 
</div>
)}
<Footer/>
    </div>
  )
}

export default Careerjobs