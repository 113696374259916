import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Japan= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Japan Visa" style={{marginTop:"20px"}}>Japan Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <h5>JAPAN (4000 INR 1 month Tourist/Visitor visa)</h5>
  <ul  className='p-3'>
  <li>Original Passport with more than 6 months validity at a time of Travel.</li>
  <li>3 Photos -- Size: 45mm&45mm (2 inches & 2 inches) with white background.</li>
  <li>Covering Letter.</li> 
  <li>Application form with Applicant Signature.</li>
  <li>Ticket Itinerary.</li>
  <li>Govt. Id Proof.</li>
  <li>Last 6 Month Bank Statement.</li>
  <li>Last 3 years IT Return.</li>
  <li>Day to Day Itinerary.</li>
  <li>Hotel Voucher – If applicant staying in hotel.</li>
  <li>Invitee Detail – If applicant visiting Relative or Friend’s Place.</li>
  <li>Original Invitation Letter.</li>
  <li>List of Visa Applicants -- List of Visa Applicants.</li>
  <li>Letter of Guarantee -- Letter of Guarantee.</li>
  <li>Alien Card.</li>
  <li>Invitee's Japan Visa copy and copy of all Japan Entry a&Depature Seal page.</li>
  <li>Income Certificate or Deposit Balance Certificate or Tax Payment Certificate.</li>
  <li>Juminhyo Certificate.</li>
  <li>Marriage Proof - If Spouse name not mentioned in the passport.</li>
  <li>Wedding Card (before marriage).</li> 
  <li>Original Marriage Certificate (after marriage).</li>
   </ul>
  <br/>
    
  <h5>Processing Time: 5-8 working days</h5>

  
 
  </div>  
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Japan