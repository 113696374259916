import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"



const Australia= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
      
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1  alt="Australia Visa" style={{marginTop:"20px"}}>Australia Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
       <div class="container mt-5">
      <p>Australia is a country encircled by the Indian and Pacific oceans. It includes major cities – Sydney, Brisbane, Melbourne, Perth, Adelaide – are coastal. Its capital, Canberra, is inland.</p>
    <p>The country is known for its Melbourne Cricket Stadium, the Great Barrier Reef, Sydney Opera House, an enormous interior desert wilderness called the Outback and exclusive animal species like kangaroos and duck-billed platypuses.</p>
  <p>Maximum Stay in Australia is 90 days on a single visit.</p>  
  <h5>Get your VISA in 3 easy steps:</h5>
  <ul  className='p-3'>
    <li>Submit Query Online</li>
    <li>Send Documents</li>
    <li>Receive VISA</li>
    
  </ul>
  <h5>Documents Require* to Apply for Visa</h5>
  <ul  className='p-3'>
    <li>Original passport and old passports, if any</li>
    <li>Your passport should be valid for at least 6 months from the date of submission of the visa application, with a minimum of three blank pages</li>
    <li>Copy of the first and last page.</li>
    <li>Confirmed return Air tickets</li>
    <li>The following forms - Form 1419 Tourist / 54 / 956 need to filled, signed and attached to the passport</li>
    <li>Note: For minors, parents have to sign the visa forms</li>
    <li>2 Latest passport-size color photographs taken against a white background with matte-finish (size 35 mm x 45 mm and 80% face close-up)</li>
    <li>Covering letter mentioning the details of your travel details, the purpose of visit and duration of stay addressed to the Australia High Commission, New Delhi</li>
    <li>Income Tax Return for the last 3 years</li>
    <li>Original bank statements reflecting transactions for the last 6 months, duly attested and signed by a bank official</li>
    <li>Travel itinerary with proof of hotel stay with passenger name and travel dates</li>
    <li>Additional documents</li>
    <li>If the applicant is employed: Salary sips for the last 6 months, Original leave letter from employer</li>
    <li>If the applicant is a company owner or self-employed: Certificate of registration of the company, Covering letter on the company letterhead and financial documents</li>
    <li>If the applicant has a family in Australia: Passport & visa copies or Immigration status of the invitee, Invitation letter, Relationship proof</li>
    <li>If the applicant is Student:</li>
    <li>Original NOC letter from school/college with ID card copy</li>
    <li>If the applicant is intending to stay more than 3 months:</li>
    <li>Chest X-Ray and medical certificate</li>
    <li>
        If the applicant age is more than 75 years
    </li>
    <li>Medical requirements required from panel doctor after getting instruction from the embassy.</li>
  </ul>
  <h5 class="mt-3">Additional Info</h5>
  <p>Our Field officer will collect the document file and visa experts will inform you in case any other documents will be required for the process. We are Delhi based service provider if the applicant's place is other than Delhi, an additional courier fee of Rs 700 will be applicable. Application Fees is non-refundable, Approval or Rejection of your VISA Application will be solely based on Embassy decision. For more details,  email us at  info@signinsoft.co.in</p>
  </div>
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Australia