import React, { useState ,useEffect} from 'react';
import ReactCardFlip from 'react-card-flip';
import image from '../../src/assets/images/ERP.png'
import image1 from '../../src/assets/images/devops.png'
import image2 from '../../src/assets/images/itservices.png'
import image3 from '../../src/assets/images/oracle.png'
import image4 from '../../src/assets/images/ai.png'
import image5 from '../../src/assets/images/sap.png'







const Service = () => {
  const [isFlipped, setIsFlipped] = useState(Array(6).fill(false)); 

  const flipCard = (index) => {
    const newFlippedState = [...isFlipped];
    newFlippedState[index] = !newFlippedState[index];
    setIsFlipped(newFlippedState);
  };
  
  return (
    <div className="container mt-5" id="Technologies">
      <div className="text-center">
        <h1 className="who"  alt="SigninSoft Technologies">Technologies</h1>
        <h3 className="ser-head">What We Offer for You</h3>


      </div>
      <div className="row mt-5">
      {Array.from({ length: 1 }, (_, index) => (
        
        <div key={index} className="col-sm-12 col-lg-4 col-xl-4 col-md-6 mt-3">
          <ReactCardFlip isFlipped={isFlipped[index]} flipDirection="horizontal">
            <div className="card border-0 shadow-lg h-100 services-card back" onClick={() => flipCard(index)}>
              
               <div className="icon-box ">
                               <h1 style={{textAlign:"center" ,color:"#ffaf43"}}><a  className="text-decoration-none titlefront">ERP Solutions</a></h1>
                               <div class="text-center">

              <img src={image} alt="Home Section" className="banner-imgservice rotating-img pulsating-img  img-fluid"/>
              </div>
<p style={{textAlign:"justify"}} className="description">Singin Soft Inc’s expert ERP Solutions for Business of different verticals.</p>


               <div  style={{textAlign:"end",marginTop:"20px"}} className='button-container'>
                <button className="button1 "><span style={{color:"#106eea",fontWeight:"bold"}}>Click More</span></button>
                </div>
            </div>
            </div>
            <div className="card border-0 shadow-lg h-100 services-card front" onClick={() => flipCard(index)}>
            
    
            <div className="icon-box">
            <h4 ><a  className="text-decoration-none title">Your ERP Solutions Provider.</a></h4>
                             <p style={{textAlign:"justify"}} className="title1 ">At Signin Soft Inc, we specialize in delivering expert ERP solutions to businesses across diverse verticals. Our team of professionals is dedicated to finding the perfect ERP software that aligns with your unique requirements. By seamlessly integrating various organizational systems, our ERP solutions pave the way for error-free transactions and efficient production.</p>
               <h4 ><a  className="text-decoration-none title"> We offer different types of ERP Solutions</a></h4>
               <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Data Security
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary "></i>Seamless Integration</li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Real-time Insights
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                Future-Ready Solutions
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
               Cost-Effective Solution
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Customization Flexibility</li>
               </ul>
            </div>
            </div>

          </ReactCardFlip>
        </div>
        ))}
        {Array.from({ length: 1 }, (_, index) => (
        <div key={index} className="col-sm-12 col-lg-4 col-xl-4 col-md-6 mt-3">
          <ReactCardFlip isFlipped={isFlipped[index+1]} flipDirection="horizontal">
            <div className="card border-0 shadow-lg h-100 services-card back" onClick={() => flipCard(index+1)}>
            <div className="icon-box ">
                               <h1 style={{textAlign:"center" ,color:"#ffaf43"}}><a  className="text-decoration-none titlefront">DevOps</a></h1>
                               <div class="text-center">

              <img src={image1} alt="Home Section" className="banner-imgservice rotating-img pulsating-img  img-fluid"/>
              </div>
<p style={{textAlign:"justify"}} className="description">DevOps is a set of practices that the processes between software development and IT teams.</p>


               <div  style={{textAlign:"end",marginTop:"20px"}} className='button-container'>
                <button className="button1 "><span style={{color:"#106eea",fontWeight:"bold"}}>Click More</span></button>
                </div>
            </div>
            </div>
            <div className="card border-0 shadow-lg h-100 services-card front" onClick={() => flipCard(index+1)}>
            <div className="icon-box">
                <h4 ><a  className="text-decoration-none title">Your DevOps Solutions Provider.</a></h4>
              <p style={{textAlign:"justify"}}className="title1 ">Your DevOps Solutions Provider: At Signin Soft Inc, we specialize in delivering top-tier DevOps services that optimize your software development and IT operations. We leverage cutting-edge tools for deployment, monitoring, logging, and configuration management to enhance your entire development lifecycle.</p>
              <h4 ><a className="text-decoration-none title"> We provide variety of DevOps solutions</a></h4>

                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Efficient Deployment
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary "></i>Robust Monitoring</li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Effective Logging
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Microservices
	              </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Automation
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Continuous Delivery</li>
               </ul>
               </div>
            </div>

          </ReactCardFlip>
        </div>))}
        {Array.from({ length: 1 }, (_, index) => (
        <div key = {index}className="col-sm-12 col-lg-4 col-xl-4 col-md-6 mt-3">
          <ReactCardFlip isFlipped={isFlipped[index+2]} flipDirection="horizontal">
            <div className="card border-0 shadow-lg h-100 services-card back" onClick={() => flipCard(index+2)}>
            <div className="icon-box ">
                               <h1 style={{textAlign:"center" ,color:"#ffaf43"}}><a  className="text-decoration-none titlefront">IT Services</a></h1>
<div class="text-center">
              <img src={image2} alt="Home Section" className="banner-imgservice rotating-img pulsating-img  img-fluid"/>
              </div>
<p style={{textAlign:"justify"}} className="description">It is form the backbone of the modern business or organization.</p>


               <div  style={{textAlign:"end",marginTop:"20px"}} className='button-container'>
                <button className="button1 "><span style={{color:"#106eea",fontWeight:"bold"}}>Click More</span></button>
                </div>
            </div>
            </div>
            <div className="card border-0 shadow-lg h-100 services-card front" onClick={() => flipCard(index+2)}>
            <div className="icon-box">
            <h4 ><a className="text-decoration-none title">Your IT Services Solutions Provider.</a></h4>
               <p style={{textAlign:"justify"}}className="title1 ">IT Services are essential components that underpin the operations of modern businesses and organizations. At Signin Soft Inc., we specialize in offering tailored solutions for business continuity management. Our expertise lies in integrating advanced, innovative, and efficient IT systems that align seamlessly with your business goals.</p>
               <h4 ><a className="text-decoration-none title">  We offer different types of IT Services Solutions</a></h4>
               <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Business Backbone
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary "></i> Custom Solutions</li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Efficiency Enhancement
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Strategic Alignment
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Variety of IT Solutions
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Scalability and Flexibility</li>
               </ul>
            </div>
            </div>

          </ReactCardFlip>
        </div>
        ))}
      </div>
      <div className="row mt-5">
      
      {Array.from({ length: 1 }, (_, index) => (
        <div key={index} className="col-sm-12 col-lg-4 col-xl-4 col-md-6 mt-3">
          <ReactCardFlip isFlipped={isFlipped[index+3]} flipDirection="horizontal">
            <div className="card border-0 shadow-lg h-100 services-card back" onClick={() => flipCard(index+3)}>
           <div className="icon-box ">
                               <h1 style={{textAlign:"center" ,color:"#ffaf43"}}><a  className="text-decoration-none titlefront">SAP</a></h1>
                               <div class="text-center">

              <img src={image5} alt="Home Section" className="banner-imgservice rotating-img pulsating-img  img-fluid"/>
              </div>
<p style={{textAlign:"justify"}} className="description">Run simple with the best in cloud, analytical, mobile and IT Solutions.</p>


               <div  style={{textAlign:"end",marginTop:"5px"}} className='button-container'>
                <button className="button1 "><span style={{color:"#106eea",fontWeight:"bold"}}>Click More</span></button>
                </div>
            </div>
            </div>
            <div className="card border-0 shadow-lg h-100 services-card front " onClick={() => flipCard(index+3)}>
            <div className="icon-box">
            <h4 ><a  className="text-decoration-none title">Your SAP Solutions Provider.</a></h4>
                             <p style={{textAlign:"justify"}} className="title1 ">At Signin Soft Inc., we specialize in delivering comprehensive SAP solutions that cater to your business needs. Our range of SAP services includes SAP consulting, implementation, application support, and maintenance. Partner with us to harness the power of SAP, the industry leader in business applications. Our expertise spans across cloud, analytics, mobility, and IT solutions, allowing you to streamline your operations.</p>
               <h4 ><a  className="text-decoration-none title "> We provides list of SAP Solutions</a></h4>
               <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4 ">
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Cloud Solutions
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary "></i>Implementation Excellence</li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Mobile Solutions
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Analytics and Business Intelligence
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Comprehensive SAP Services
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Run Simple</li>

               </ul>
            </div>
            </div>

          </ReactCardFlip>
        </div>
      ))}
      {Array.from({ length: 1 }, (_, index) => (
        <div key={index} className="col-sm-12 col-lg-4 col-xl-4 col-md-6 mt-3">
          <ReactCardFlip isFlipped={isFlipped[index+4]} flipDirection="horizontal">
            <div className="card border-0 shadow-lg h-100 services-card back" onClick={() => flipCard(index+4)}>
            <div className="icon-box ">
                               <h1 style={{textAlign:"center" ,color:"#ffaf43"}}><a  className="text-decoration-none titlefront">AI</a></h1>
                               <div class="text-center">

              <img src={image4} alt="Home Section" className="banner-imgservice rotating-img pulsating-img  img-fluid"/>
              </div>
<p style={{textAlign:"justify"}} className="description">Artificial Intelligence is the ability of a digital computer or computer-controlled robot to perform tasks commonly associated with intelligent.</p>


               <div  style={{textAlign:"end",marginTop:"5px"}} className='button-container'>
                <button className="button1 "><span style={{color:"#106eea",fontWeight:"bold"}}>Click More</span></button>
                </div>
            </div>
            </div>
            <div className="card border-0 shadow-lg h-100 services-card front" onClick={() => flipCard(index+4)}>

            <div className="icon-box">
            <h4 ><a  className="text-decoration-none title">Your AI Solutions Provider.</a></h4>
                             <p style={{textAlign:"justify"}} className="title1 ">At Signin Soft Inc., we bring the power of Artificial Intelligence (AI) to your fingertips, enabling you to solve complex problems and drive innovation like never before. With AI, machines can simulate human intelligence, making them capable of understanding, learning, and decision-making. Our AI solutions offer a range of capabilities that revolutionize the way businesses operate and engage with  customers. 
               We can help you in creating best end to end.</p>
               <h4 ><a  className="text-decoration-none title">We provides list of AI Solutions</a></h4>
               <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Unlocking Intelligence
                     </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary "></i>Customized Solutions</li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Automation and Efficiency
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Data-Driven Insights
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Machine Learning
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Predictive Analytics</li>
               </ul>
            </div>
</div>
          </ReactCardFlip>
        </div>
      ))}
      {Array.from({ length: 1 }, (_, index) => (
        <div key={index} className="col-sm-12 col-lg-4 col-xl-4 col-md-6 mt-3">
          <ReactCardFlip isFlipped={isFlipped[index+5]} flipDirection="horizontal">
            <div className="card border-0 shadow-lg h-100 services-card back" onClick={() => flipCard(index+5)}>
            <div className="icon-box ">
                               <h1 style={{textAlign:"center" ,color:"#ffaf43"}}><a  className="text-decoration-none titlefront">Oracle</a></h1>
                               <div class="text-center">

              <img src={image3} alt="Home Section" className="banner-imgservice rotating-img pulsating-img  img-fluid"/>
              </div>
<p style={{textAlign:"justify"}} className="description">Oracle services help organizations implement processes, operating models & information Systems.</p>


               <div  style={{textAlign:"end",marginTop:"10px"}} className='button-container'>
                <button className="button1 "><span style={{color:"#106eea",fontWeight:"bold"}}>Click More</span></button>
                </div>
            </div>
            </div>
            <div className="card border-0 shadow-lg h-100 services-card front" onClick={() => flipCard(index+5)}>
            <div className="icon-box">
            <h4 ><a  className="text-decoration-none title">Your Oracle Solutions Provider.</a></h4>
                             <p style={{textAlign:"justify"}} className="title1 ">Our Oracle services offer a comprehensive suite of solutions that enhance your business processes, optimize operations, and drive innovation. Whether you're a small enterprise or a large corporation, our Oracle experts  finds  solutions to fit your unique needs and industry requirements.</p>
               <h4 ><a  className="text-decoration-none title "> We provides list of Oracle Solutions.</a></h4>
               <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4 ">
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Oracle Cloud Integration
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary "></i>Business Process Improvement</li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Industry-Specific Solutions
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Data Management and Analytics
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
                  Supply Chain Management
                  </li>
                  <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Customer Relationship Management</li>

               </ul>
            </div>
            </div>

          </ReactCardFlip>
        </div>
      ))}
      </div>
    </div>
  );
};

export default Service;
