import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"



const Malaysia= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
      
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Malaysia Visa" style={{marginTop:"20px"}}>Malaysia Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <h5>Types</h5>
  <ul  className='p-3'>
    <li>15 Days eNTRI Visa- 5000 INR incl all charges (Processing time: Within 1 hour).</li>
    <li>30 Days Visa Single Entry- 9500 INR incl all charges (Processing time: 2-3 working days).</li>
    <li>1-year Multiple entry visa- 15000 INR incl all charges ( allowed to stay for max 60 days for each entry). (Processing time: 5-7 working days)</li>
    </ul>
  <h5>Additional documents</h5>
 
  <ul  className='p-3'>
    <li>  Original passport with old passports, if any.</li>
    <li>Passport should be valid for at least 6 months from the date of submission of the visa, with a minimum of two blank pages.</li>
    <li>2 recent passport-size color photographs taken against a white background with matte-finish (size 35 mm x 45 mm and 80% face close-up).</li>

  </ul>
    
  <h5>Documents required: for entry and 1-month Evisa</h5>

  <ul  className='p-3'> 
    <li>  Scanned color copy of the first and last page of your passport.</li>
    <li>Passport should be valid for at least 6 months.</li>
    <li>Scanned color copy of your passport-size photograph taken against a white background</li>
  </ul>
 
  </div>  
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Malaysia