import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Germany= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
      
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Germany Visa" style={{marginTop:"20px"}}>Germany Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <p>Germany(Processing Fee: 3500 INR)</p>
<br/>

      <p>The applicant has to submit his/her original Passport having a validity of minimum of three months (from the date of return) and minimum two blank pages for visa stamps.</p>
  <h5>Please note:</h5>
  <ul  className='p-3'>
  <li>Passports issued beyond 10 years will not be accepted by the Embassy</li>
      <li>Attach all your old passports (if any)</li>
      <li>Handwritten passports will not be accepted by the Consulate.</li>
      <li>Visa application form needs to be duly filled and signed by the applicant.</li>
    
      </ul>
      <h5>Please note: </h5>


<ul  className='p-3'>
<li>The applicant’s mobile number should be mentioned in the visa form so that the Embassy / Consulate can contact the applicant at any point of time during the visa processing.</li>
      <li>Two recent passport-size colored photographs with a matte or semi-matte finish, 80% face coverage, white background and without border (Size: 35mm x 45mm).</li>
      <li> <b>Please note:</b> that those who reside in Chennai will have to provide photographs with different specifications: Two recent passport size colored photographs with glossy finish, 80% face coverage, white background and without border (Size: 35mm x 45mm).</li>
      <li><b>Please note:</b>  Photograph should not be more than 3 months old, scanned/stapled and should not be used in any of the previous visas.</li>
      <li>The applicant has to fill the online Visa Application Form. A printout of the filled form has to be attached with the application for submission.</li>
      <li> <b>Please note:</b>  The Applicant’s mobile number should be mentioned in the visa form as Embassy / Consulate can contact the applicant at any point of time during visa processing.</li>
      <li>Two recent passport size colored photographs with a matte or semi-matte finish, 80% face coverage, white background and without border (Size: 35mm x 45mm).</li>
      <li><b>Please note:</b>  The photograph should not be more than 3 months old, scanned/stapled and should not be used in any of the previous visas.</li>
      <li>A Covering Letter from applicant mentioning a name, designation, passport number, purpose and duration of visit in brief. The letter should be duly signed by authorized signatory with company stamp, name and designation of the signatory. The letter should be addressed to: The German Consulate General, Mumbai</li>
      <li><b>Please Note:</b> </li>
      <li>The Covering Letter must be on an official letterhead if an applicant is self-employed and on a blank paper if employed in a firm.</li>
    
</ul>

<h5>The sponsor of the applicant has to furnish the following documents:</h5>
<ul  className='p-3'>
<li>Invitation Letter</li>
      <li>An invitation letter by the host in Germany mentioning name and duration of stay.</li>
      <li>Formal Obligation Letter.</li>
      <li>If the sponsor is not a German citizen: A4 sized copy of the sponsor's passport including his/her valid residence permit.</li>
      <li>Proof of Sufficient Funds</li>
      <li>Guarantee of expenses (for example, latest 3 months bank statement and salary slips)</li>
      <li>As Proof of Financials, the applicant has to submit the following in the said scenarios:</li>
      <li>If the applicant is Employed</li>
      <li>Personal Income Tax Returns (ITR) for the last 3 years.</li>
      <li>Last three months of salary slip.</li>
      <li>Personal Bank Statement for the last six months mentioning the bank’s name, bank’s telephone number clearly.</li>
      <li>If the applicant is Self-Employed</li>
      <li>Personal & Company income tax returns (ITR) for last 3 years.</li>
      <li>Personal & Company bank statement for last six months mentioning the bank’s name, bank’s telephone number also mentioning the applicant's name and address.</li>
    
</ul>
    
<h5>Please Note:</h5>
<ul  className='p-3'>
<li>Bank Statements should be printed and stamped on A4 size sheet.</li>
      <li>As Proof of Occupation, he/she has to submit the following documents:</li>
      <li>If the applicant is Self–Employed:</li>
        <li>Company Registration Certificate / Certificate of Incorporation</li>
        <li>Brief Company Profile</li>
        <li>Articles of Memorandum (In case the applicant is MD/Director)</li>
        <li>Proof of Proprietorship / Partnership (In case the applicant is Proprietor / Partner in a firm)</li>
        <li>Import / Export License (if applicable)</li></ul>
        <h5>If the applicant is employed in any firm: Proof of employment like Salary Slip or Appointment Letter / NOC and Leave Sanction letter from employer</h5>

<h5>If the applicant is a student:</h5>
 <ul  className='p-3'>
<li>Leave Sanction Letter from school</li>
<li>Parents have to provide Income Tax Returns (ITR), bank statement and No Objection Certificate (NOC) letter</li>
</ul>
<h5>If the applicant is a minor:</h5>
<ul  className='p-3'>
    <li>Birth Certificate (original and A4 sized copy)</li>
    <li>Letter from School confirming current school admission and sanctioned leave of absence (original, no copy)</li>
    <li>Consent Letter from both parents regarding child’s travel (original, no copy)</li>
    <li>A4 sized copy of Parents’ Passports</li>
    <li>Death Certificate of parent(s) - if applicable (original and A4 sized copy)</li>
    <li>Court Order regarding child’s custody - if applicable (original and A4 sized copy)</li>
    <li>The applicant has to submit his/her hotel confirmation/guarantee from the host for the entire period of stay in the Schengen area, as proof of accommodation.</li>
</ul>
<h5>Please note:</h5>
<ul  className='p-3'>
    <li>The hotel should match the ticket dates and maximum stay should be in Germany.</li>
    <li>He/she must submit their return air ticket displaying their Ticket Itinerary prominently.</li>
    <li>The applicant must hold a valid overseas Medical Insurance for the stay duration amounting to 30,000 Euros, which needs to be submitted in original along with the photocopy.</li>
    <li>Applicant has to personally visit the center for his biometric finger printing.</li>
</ul>
  </div>
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Germany