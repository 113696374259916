import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Usa= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
    
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="USA Visa" style={{marginTop:"20px"}}>USA Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <p>Types: B1/B2 Tourist/Business Visa (Processing Fee: 5000 INR incl of all charges)</p>
  <h5>Documents required for USA Visa application</h5>
  <ul  className='p-3'>
    <li>Original passport and old passports, if any</li>
    <li>Passport should be valid for at least 6 months from the date of return, with a minimum of two blank pages</li>
    <li>Two copies of the first and last page of your passport duly attested</li>
    <li>Confirmed return air tickets</li>
    <li>The visa application form duly filled and signed by the applicant</li>
  <li>2 recent passport-size colour photographs taken against a white background, size (35 mm x 45 mm with 80% face close up)</li>
    
  </ul>
  <h5>Note:</h5>
  

<li>Photograph should not be more than 3 months old, scanned/stapled and should not be used in any of the previous visas.</li>
<li>Covering letter mentioning details of travel, details of the traveler, purpose of visit and duration of stay. The letter should be duly signed by authorized signatory with company stamp and addressed to the Consulate General of the United States of America, Hyderabad.</li>

  <h5>The applicant's sponsor needs to furnish the following documents:</h5>
  <ul  className='p-3'>
  <li>Invitation letter.</li>
  <li>Sponsorship letter stating the declaration of support.</li> 
  <li>Financial documents of the sponsor.</li>
  <li>Proof of residency - Green card or a valid visa of the inviter from USA.</li>  </ul>
    
  <h5>Additional Documents from Applicant:</h5>
  <ul  className='p-3'>
  <li>Employed / Salaried</li>
  <li>Cover letter stating the purpose of travel</li>
  <li>Leave letter stating the duration</li>
  <li>NOC from an employer if the applicant is working in a Central or State Government-run organization</li>
  <li>Income Tax Returns for the last 3 years or Form 16A of last 3 years</li>
  <li>Bank statements for the last 6 months</li>
  <li>Salary slips for the last 6 months</li>
  <li>Investment documents - fixed deposits, mutual fund statements, insurance policies, fixed assets, etc.</li>

  <li>Business owners</li>
  <li>Cover letter on business letterhead stating the purpose of travel</li>
  <li>Income Tax Returns for the last 3 years</li>
  <li>Bank statements for the last 6 months</li>
  <li>Business / Company’s income tax returns and account statements for the last 3 years</li>
  <li>Business Registration documents – Memorandum of Association or Partnership Deed</li>
  <li>Investment documents - fixed deposits, mutual fund statements, insurance policies, fixed assets, etc</li>

  <li>Retired travelers</li>
  <li>Income Tax Returns for the last 3 years</li>
  <li>Updated Personal bank statements for the last 6 months (Generally, bank balance should be equivalent to tour cost)</li>
  <li>Retirement proof</li>
  <li>Pension payment documents, if any</li>
  <li>Investment documents - fixed deposits, mutual fund statements, insurance policies, fixed assets, etc</li>
  <li> Farmers</li>
  <li>Cover letter stating the purpose of travel</li>
  <li>Explanation letter for non–availability of income tax returns</li>
  <li>Bank statements for last 6 months</li>
  <li>Extract of 7 x 12 and it must be translated in English of Rs 20 Stamp Paper</li>
  <li>Sale receipts of agricultural produce</li>
  <li>Investment documents - fixed deposits, mutual fund statements, insurance policies, fixed assets etc</li>

  <li>Students</li>
  <li>School / College NOC, if applying for a visa during Non–vacation period</li>
  <li>School / College ID if applying during vacation period</li>

  <li>Honeymoon couples (If applying before the wedding)</li>
  <li>NOC from bride’s parents on Rs 20 Stamp Paper and it should be notarized</li>
  <li>Signature and identity proof of both parents</li>
  <li>Wedding card copies</li>

  <li>Other cases</li>
  <li>Child below 18 years traveling alone / with either parent: NOC on Rs 20 Stamp Paper (notarized) from both parents / non-traveling parent’s NOC along with signature proof</li>
  <li>Wife / Daughter traveling alone: NOC from husband/parents on letterhead along with signature proof</li>
  <li>Widow: Death certificate of husband</li>
  </ul>
 
  </div>  
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Usa