import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/Tourist.png"
import image2 from "../../src/assets/images/flight1jpg.jpg"
import image3 from "../../src/assets/images/shield.svg"
import image4 from "../../src/assets/images/radar.svg"
import image5 from "../../src/assets/images/notebook.svg"



const FlightTickets= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1  alt="Flight Tickets" style={{marginTop:"20px"}}>Flight Tickets</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
  
</section>

       <div className="container mt-5">

      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-4 col-lg-4">
            <img src={image2} alt="FlightTickets" className="img-fluid"/>

        </div>
        <div className="col-sm-12 col-md-8 col-xl-8 col-lg-8">
          <h3>Experience Unmatched and Affordable Flight Booking</h3>
          <p style={{textAlign:"justify"}}>Signin Soft stands out as a prominent flight ticket booking agency within the Indian travel industry, offering an ideal platform for all your travel needs. Our platform provides the quickest flight ticket services in India, presenting an extensive array of options to secure the finest flight ticket deals. Our user-friendly services guarantee secure payment methods, ensuring an unforgettable travel or holiday experience.

At Signin Soft, you have the flexibility to pay via Debit Card, Credit Card, or Internet Banking for your flight ticket purchases. Choose from a diverse range of airline options including Spice Jet, Air India, Go Indigo, Go Air, and more. Whether you're in search of international or domestic flight tickets, our priority is delivering exceptional customer satisfaction.

Embrace an unparalleled flight ticket booking journey with Signin Soft, as we provide comprehensive services encompassing Round Trip Flight Tickets, One-Way Flight Tickets, and Multi-City Flight Tickets.

Moreover, through our Digital Marketing Training, rekindle your creative prowess and formulate impactful marketing strategies to promote products or services across various business sectors.

</p>
        </div>
      </div>
    </div>
    <div class="container mt-5">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-xl-4 col-lg-4 text-center">
            <img src={image3} style={{height:"80px"}}/>
            <h5 class="mt-3">Best Price</h5>
                <p>Signin Soft offer all our services with affordable and best Prices.</p>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-4 col-lg-4 text-center">
            <img src={image4} style={{height:"80px"}}/>
            <h5 class="mt-3">Signin Soft Review</h5>
            <p>I have amazing visit to Malaysia.This tour helps me having excellent</p>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-4 col-lg-4 text-center">
            <img src={image5} style={{height:"80px"}}/>
            <h5 class="mt-3">Follow on</h5>
            <p>We have 424 people like us page .Please signup for more details .</p>
        </div>
    </div>
    </div>
<div class="container-fluid mt-5">
    <div class="text-center p-5" style={{height:"250px",backgroundColor:"#f1f1f1"}}>
   <h2 style={{color: "#03295c"}}> Our Team Can Help</h2>
   <h5> For Support you can contact us at</h5>
    <h5>Email : info@signinsoft.co.in</h5>
   <h5> Phone : 9704447788</h5>
</div>
</div>

<br/>


    
   
<Footer/>
    </div>
  )
}

export default FlightTickets