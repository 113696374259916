import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/Tourist.png"
import imgg1 from "../../src/assets/images/111571489366.jpg"



const TouristPackage= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
      
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Tourist Packages" style={{marginTop:"20px"}}>Tourist Packages</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>

       <div className="container mt-5">

      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-4 col-lg-4">
            <img src={imgg1} alt="TouristPackage" className="img-fluid"/>

        </div>
        <div className="col-sm-12 col-md-8 col-xl-8 col-lg-8">
          <h3>Why Choose Signin Soft for Booking?</h3>
          <p>Experience cost-effective Domestic and International Vacations with Signin Soft. Our range of Holiday packages is abundant, catering to various budgets, ensuring affordability.</p>
        </div>
      </div>
    </div>
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default TouristPackage