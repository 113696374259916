import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../src/assets/images/white-logo.png';

const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/Home">
            <img src={logo} alt="SigninSoft Logo" className="signin-logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav" style={{ marginLeft: 'auto' }}>
              <Link
                className="nav-link scrollto active navhome"
                aria-current="page"
                style={{ whiteSpace: 'nowrap', padding: '10px 20px 10px 28px' }}
                to="/#home"
              >
                Home
              </Link>
              <Link
                className="nav-link scrollto navhome"
                style={{ whiteSpace: 'nowrap', padding: '10px 20px 10px 28px' }}
                to="/#About"
              >
                About
              </Link>
              <Link
                className="nav-link scrollto navhome"
                style={{ whiteSpace: 'nowrap', padding: '10px 20px 10px 28px' }}
                to="/#Technologies"
              >
                Technologies
              </Link>

              <li className="nav-item dropdown dropdown-mobile">
                <a
                  className="nav-link dropdown-toggle nav-link scrollto navhome"
                  style={{ whiteSpace: 'nowrap', padding: '10px 20px 10px 28px' }}
                  id="navbarDropdownMobile"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMobile">
                  <li>
                    <Link className="dropdown-item" to="/SoftwareServices">
                      Software Services
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/WebDevelopment">
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/DigitalMarketing">
                      Digital Marketing
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li className="nav-item dropdown dropdown-mobile">
                    <a
                      className="nav-link dropdown-toggle nav-link scrollto navhome"
                      style={{ whiteSpace: 'nowrap', padding: '10px 20px 10px 28px' }}
                      id="navbarDropdownVisa"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Visa Services
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownVisa">
                      <li>
                        <Link className="dropdown-item" to="/Visa">
                          Visas
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/TouristPackage">
                          Tourist Packages
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/FlightTickets">
                          Flight Tickets
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <Link
                className="nav-link scrollto navhome"
                style={{ whiteSpace: 'nowrap', padding: '10px 20px 10px 28px' }}
                to="/Careerjobs"
              >
                Career
              </Link>
              <Link
                className="nav-link scrollto navhome"
                style={{ whiteSpace: 'nowrap', padding: '10px 20px 10px 28px' }}
                to="/#contact"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
