import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const China= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="China Visa" style={{marginTop:"20px"}}>China Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
  <h5 class="mt-3">Documents for China Visa application</h5>
<ul  className='p-3'>
<li>Standard application</li>
      <li>Original passport and old passports, if any</li>
      <li>Your passport should be valid for at least 6 months from the day of arrival in China, with a minimum of two blank pages</li>
      <li>Confirmed return air tickets</li>
      <li>Day-wise itinerary of your stay in China</li>
      <li>Visa application form duly filled and signed</li>
      <li>2 recent passport-size color photographs taken against a white background with matte-finish (size 48 mm x 33 mm and 70% face close-up)</li>
     <li> Note: Photograph should not be more than 3 months old, scanned/stapled and not used in any of the previous visas.</li>
      <li>Covering letter mentioning details of travel, details of the traveler, purpose of visit and duration of stay. The letter should be duly signed by authorized signatory with company stamp and addressed to the ‘Consulate General of the People's Republic of China.</li>
      <li>Original bank statements reflecting transactions of the last 6 months with a minimum balance of Rs 1,60,000 per traveler, duly attested and signed by a bank official</li>
      <li>Hotel confirmation details</li>
</ul>
<h5 class="mt-3">If your passport has not been issued from South  and you have submitted your application from South, one of the following documents will be required</h5>
<ul  className='p-3'>
<li>Valid Lease and License Agreement</li>
      <li>Address proof on the company’s original letterhead with authorized signatory sign and company stamp</li>
      <li>MTNL, BSNL bill of last month</li>
      <li>Original society letter with stamp and sign</li>
      <li>Apart from the above mentioned documents, the applicant might have to submit additional documents as and when requested by the Consulate</li>
      <li>Covering letter from the company in India</li></ul>
<h5 class="mt-3">
Documents of the commercial activity issued by a trade partner in China, or trade fair invitation. The invitation letter should contain:
</h5>
<ul  className='p-3'>
<li>Information of the applicant - name, gender and date of birth</li>
      <li>Details such as purpose of visit, travel dates, and financial documents</li>
      <li>Details of the invitee - name, contact number, address and business documents</li>
   
    
</ul>
<h5 class="mt-3">Additional documents</h5>

<h5>If the applicant is employed:</h5>
<ul  className='p-3'>
    <li>Salary slips for the last 6 months
</li>
    <li>Original leave letter from employer</li>
   
   
</ul>
<h5 class="mt-3">If the applicant is a company owner or self-employed: </h5>
    <ul  className='p-3'>
        <li>Covering letter on the company letter head and financial documents.</li>
<li>If the applicant has a Friend / Family in China.</li>
<li>If you intend to stay with relatives or friends, invitation letter and and photocopies of their relatives' Residence Permits in China.</li>
        <li>ITR for last 3 years</li>
        <li>Proof of Occupation</li>
    </ul>
    <h5 class="mt-3">Processing Time: 7-8 working Days </h5>

</div> 
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default China