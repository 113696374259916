import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Dubai= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Dubai Visa" style={{marginTop:"20px"}}>Dubai Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <p>Types: Single entry short term visa (30 days)-8,000 INR incl of all charges</p>
    <p>Single entry Long term Visa (90 days)- 19,000 INR incl of all charges</p>
  <h5>Documents required for Dubai visa application</h5>
  <ul  className='p-3'>
    <li>Scanned color copy of your pan card</li>
    <li>Scanned color copy of the first and last page of your passport</li>
    <li>Passport should be valid for at least 6 months</li>
    <li>Scanned color copy of your passport-size photograph taken against a white background</li>
    <li>Confirmed return air ticket (not mandatory for application)
    </li>
    <p><b>Processing Time: 

    </b>4-5 Working Days</p>
    
  </ul>
 
 
  </div>
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Dubai