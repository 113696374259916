import React from 'react'
import image from '../src/assets/images/footer-logo.png'

const Footer = () => {
  return (
   <div>
    <footer class="footer-section">
        <div class="container">
           
            <div class="footer-content pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 mb-50">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="/#home"><img src={image} class="img-fluid" alt="logo"/></a>
                            </div>
                            <div class="footer-text">
                                <p>Signin Soft Inc. is founded with a dual purpose: not only to provide exceptional services that align with our clients' business needs, but also to empower them to seize fresh opportunities that offer a competitive advantage in today's fierce corporate landscape.</p>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><a href="/home">Home</a></li>
                                <li><a href="/#About">About</a></li>
                                <li><a href="/#Technologies">Technologies</a></li>
                                <li><a href="/Careerjobs">Career</a></li>
                                <li><a href="/#contact">Contact</a></li>
                                <li><a href="/#Technologies">ERP</a></li>
                                <li><a href="/#Technologies">AI</a></li>
                                <li><a href="/#Technologies">Oracle</a></li>
                                <li><a href="/#Technologies">Devops</a></li>
                                <li><a href="/#Technologies">SAP</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Follow us</h3>
                            </div>
                            <div class="footer-social-icon">
                              
                              <a href="	https://www.facebook.com/signinsoft.net" target="_blank"><i class="fa fa-facebook-f"></i></a>
                              <a href="https://twitter.com/signin_soft" target="_blank"><i class="fa fa-twitter-square"></i></a>
                              <a href="https://www.linkedin.com/company/signinsoft" target="_blank"><i class="fa fa-linkedin-square"></i></a>
                          </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="copyright-area">
            <div class="container">
                
                    <div class="text-center">
                        <div class="copyright-text">
                            <p>© Copyright Signin Soft Inc. All Rights Reserved <br/>Designed by <a href="https://www.knackhook.com/" target="_blank">KnackHook</a></p>
                        </div>
                    </div>
                    
                
            </div>
        </div>
    </footer>

   </div>
   
  )
}

export default Footer