import React,{useEffect} from 'react'
import image1 from '../../src/assets/images/about_cyber.jpg'
import image2 from '../../src/assets/images/about2.png'
import Card from "react-animated-3d-card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const About = () => {
  
 
  
  
    const cards = [
        {
            
          title: "Innovative Solution",
          text:
          "Quality Beyond Compromise, Our products undergo rigorous testing and refinement to ensure that they meet the highest standards, providing you with a reliable and efficient solution.",
         icon: (
                <div className="icon2">
                  <i className="fa fa-thumbs-up about-icon" style={{ fontSize: '24px' }}></i>
                </div>
              )
        },
        {
          title: "Expert Team",
          text:
          "Pioneers cutting-edge solutions for real-time communication content monitoring. Reach out to us today to explore how we can assist you in overcoming your business challenges and achieving your goals together.",            
         icon: (
                <div className="icon2">
                  <i className="fa fa-user about-icon" style={{ fontSize: '24px' }}></i>
                </div>
              )
        }
      ];
  return (
        <div className="container mt-5" id="About">
    <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="cyber-about-img text-center mb-30 mb-lg-0">
                <img src={image1} alt="VR" className="img-fluid"/>
                <div className="row g-0">
                    <div className="col-lg-5 col-sm-12">
                        <div className="sheild-img">
                            <img src={image2} alt="Sheild" className="img-fluid d-none d-lg-block"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="pe-2">
                            <div className="cyber-about-count-box d-md-flex bg-white p-4 mt-3">
                                <div className="pe-3">
                                    <h2>20+</h2>
                                </div>
                                <div>
                                    <h5 className="h6">Years Experience</h5>
                                    <p className="mb-0">We Just Achieved Big</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
            <h1 className="who mt-3" alt="About Signin Soft">About Signin Soft</h1>
            <h5 className="signin-text">At Signin Soft Inc., we love what we do! We work in a passionate work environment</h5>
           <p style={{textAlign:"justify"}}>With an extensive background enriched by specialized skills, we are adept at delivering cutting-edge technology solutions across diverse industries. From optimizing operational efficiency to streamlining processes, we are dedicated to helping businesses achieve their goals and unlock the desired returns on investment.</p>
           <div className="row">
           

<Grid container spacing={4}>
      {cards.map((item, index) => (
        <Grid item xs={12} sm={12} md={6}key={index}>
          <Card
            style={{
              width: "100%",
              height: "220px",
              cursor: "pointer",
              boxShadow: "none", 
              transition: "transform 0.5s", 
              transformStyle: "preserve-3d", 
              backfaceVisibility: "hidden", 
              border: "none",
              
            }}
            className="card-3d"
          >
            <CardContent>
            {item.icon}
              <Typography gutterBottom variant="h5" component="div">
                
                <h4 className="title"><a href="" class="text-decoration-none title">{item.title}</a></h4>

              </Typography>
              <Typography variant="body2" color="text.secondary">
                
                <p  className="description">{item.text}</p>

              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>

        
    </div>
</div>
</div>
</div>

  )
}

export default About