import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Singapore= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Singapore Visa" style={{marginTop:"20px"}}>Singapore Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <p>Tourist/Visit/Business Visa (30 days)-4,000 INR incl all charges</p>
  <h5>Documents Require to Apply for Singapore Visa</h5>
   <ul  className='p-3'>
    <li>Original passport and old passports, if any</li>
    <li>Passport should be valid for at least 6 months from the date of submission of the visa, with a minimum of two blank pages</li><li>Two copies of the first and last page of your passport duly attested</li>
    <li>Confirmed return air tickets.</li>
    <li>Visa application form duly filled and signed.</li>
  <li>2 recent passport-size colour photographs taken against a white background, size (35 mm x 45 mm with 80% face close up)</li>
    
  <li>Covering letter mentioning the details of travel, details of the traveler with contact number and expense ownership details</li><li>Original bank statements for the last 6 months reflecting a minimum balance of Rs 52,000, that has been attested by a bank official</li>
  <li>The letter must be filled and signed with a black pen</li>
  <li>Original bank statements reflecting transactions of the last 6 months, duly attested and signed by a bank official.
</li>
<li>Evidence of occupation and of the monthly income: Income Tax Returns for the last 3 years, Payslips, a letter from employer certifying employment, Employee ID, Student ID, Business license.
</li>

  </ul>
  <h5>Additional documents required for Singapore business visa</h5>

  <ul  className='p-3'>
  <li>Original covering letter from Indian company addressed to 'The Visa officer Singapore Consulate'.</li>
    <li>Invitation letter from overseas company addressed to 'The Visa officer Singapore Consulate'.</li>
  </ul>
    
  
 <p><b>Processing Period: 5-7 working days</b></p>
 
  </div>  
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Singapore