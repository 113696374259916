import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/softwareservices.png"
import img1 from "../../src/assets/images/11571749896.png"
import img2 from "../../src/assets/images/21571750155.png"
import img3 from "../../src/assets/images/31571750176.png"
import imgg1 from "../../src/assets/images/11571814609.jpg"
import imgg2 from "../../src/assets/images/2.jpg"
import imgg3 from "../../src/assets/images/3.jpg"
import imgg4 from "../../src/assets/images/4.jpg"
import imgg5 from "../../src/assets/images/5.jpg"
import imgg6 from "../../src/assets/images/6.jpg"


const SoftwareServices= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
      
 <section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 style={{marginTop:"20px"}}>Software Services</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      

      <h1 alt="About Software Services" class="text-center">About Software Services</h1>
      <p class="mt-2" style={{textAlign:"justify"}}>SIGNIN SOFT enables enterprises to address the modern challenges posed by technology-driven disruptions in the business landscape. We incorporate cutting-edge technologies and innovative business models that prompt companies to revolutionize their operations. Our services encompass software application development and support, facilitating business proprietors in understanding and fulfilling their customers' requirements through digital, customer-centric changes. As a premier and inventive website development agency situated in Hyderabad, India, our team comprises dynamic, highly skilled designers, developers, and programmers.</p>
   
   
    </div>
    <div class="container">
      <h1 class="text-center">Services</h1>
  <div class="row mt-5">
      <div class="col-sm-12 col-md-12 col-xl-4 col-lg-4 ">
              <div class="card border-0 shadow-lg p-3 text-center mt-3"  style={{height:"450px"}}>
                  <div class="text-center">
                      <img src={img1} alt="software Section"/>
                  </div>
  
                  <h5>Staffing & Augmentation</h5>
                  <p style={{textAlign:"justify"}}>Signin Soft provides an exceptionally adaptable setting for staffing and recruitment. We have gained a reputation as the most reliable staffing partner, thanks to our cutting-edge methodologies.</p>
              </div>
      </div>
      <div class="col-sm-12 col-md-12 col-xl-4 col-lg-4 ">
          <div class="card border-0 shadow-lg p-3 text-center mt-3" style={{height:"450px"}}>
              <div class="text-center">
                  <img src={img2} alt="software Section" style={{height:"150px"}} />
              </div>
  
              <h5>Corporate Training</h5>
              <p style={{textAlign:"justify"}}>Signin Soft Learning Services stands as a distinguished leader in Corporate Training and Skill Development, offering a wide range of IT training and services to businesses.</p>
          
              </div>
      </div>
      <div class="col-sm-12 col-md-12 col-xl-4 col-lg-4 ">
          <div class="card border-0 shadow-lg p-3 text-center mt-3" style={{height:"450px"}}>
              <div class="text-center">
                  <img src={img3} alt="software Section"  style={{height:"150px"}}/>
              </div>
  
              <h5>IT Consulting</h5>
              <p style={{textAlign:"justify"}}>Signin Soft IT Consulting provides comprehensive IT-enabled solutions and services to drive the transformation of your business. With our combined consulting and IT services, backed by a team of skilled experts, we ensure your business achieves its goals.</p>
          
          </div>
      </div>
  </div>
  <div class="row mt-5">
      <div class="col-sm-12 col-md-12 col-xl-4 col-lg-4 ">
              <div class="card border-0 shadow-lg p-3 text-center mt-3"  style={{height:"450px"}}>
                  <div class="text-center">
                      <img src={img1} alt="software Section"/>
                  </div>
  
                  <h5>ETL Tools</h5>
                  <p style={{textAlign:"justify"}}>Signin Soft specializes in projects involving:
a) Informatica PowerCenter,
b) SAS Data Management, and
c) SAP Data Services.
These ETL (Extract, Transform, Load) tools streamline the extraction process, establishing a more efficient and dependable workflow.</p>
              </div>
      </div>
      <div class="col-sm-12 col-md-12 col-xl-4 col-lg-4 ">
          <div class="card border-0 shadow-lg p-3 text-center mt-3" style={{height:"450px"}}>
              <div class="text-center">
              <img src={img1} alt="software Section"/>
              </div>
  
              <h5>Cyber Security</h5>
              <p style={{textAlign:"justify"}}>Signin Soft is actively engaged in Cybersecurity projects, primarily focusing on SOC analysts. A Security Operations Center (SOC) analyst plays a critical role in cybersecurity by monitoring and mitigating threats to an organization's IT infrastructure.</p>
          
              </div>
      </div>
      
  </div>
  
  
  <div class="row mt-5">
  <h1 class="text-center">Services</h1>

  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
      <div class="a-box">
          <div class="img-container">
            <div class="img-inner">
              <div class="inner-skew">
                <img src={imgg1} alt="software Section" style={{height:"170px",marginTop:"22px",marginLeft:"-10px"}}/>
              </div>
            </div>
          </div>
          <div class="text-container">
            <h3>Hadoop</h3>
            <div >
            When it comes to BIG DATA and Hadoop, Signin Soft's approach is centered around achieving comprehensive knowledge and proficiency in the most up-to-date tools, products, and technologies. This commitment ensures that our clients' requirements are not only fulfilled but also that their data is easily accessible and accelerated.
          </div>
        </div>
        </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
      <div class="a-box">
          <div class="img-container">
            <div class="img-inner">
              <div class="inner-skew">
                <img src={imgg2} alt="software Section" style={{height:"170px",marginTop:"22px",marginLeft:"-10px"}}/>
              </div>
            </div>
          </div>
          <div class="text-container">
            <h3>Enterprise Applications</h3>
            <div >
            In the realm of Enterprise Applications, Signin Soft excels in facilitating the integration of organizational systems. This integration results in enhanced data entry processes, the implementation of optimal practices, and the seamless, efficient flow of information. The ultimate outcome is an elevated level of customer satisfaction.          </div>
        </div>
        </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
      <div class="a-box">
          <div class="img-container">
            <div class="img-inner">
              <div class="inner-skew">
                <img src={imgg3} alt="software Section" style={{height:"170px",marginTop:"22px",marginLeft:"-10px"}}/>
              </div>
            </div>
          </div>
          <div class="text-container">
            <h3>ERP Solutions</h3>
            <div >

            Signin Soft offers comprehensive ERP solutions to companies across various sizes and industries globally. Our services encompass pre-implementation consulting, ERP software solution implementation, and ERP software licensing, ensuring a holistic approach to addressing the needs of our clients.          </div>
        </div>
        </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5">
      <div class="a-box">
          <div class="img-container">
            <div class="img-inner">
              <div class="inner-skew">
                <img src={imgg4} alt="software Section" style={{height:"170px",marginTop:"22px",marginLeft:"-10px"}}/>
              </div>
            </div>
          </div>
          <div class="text-container">
            <h3>Digital Dashboards & BI</h3>
            <div >
            Signin Soft has been building innovative dashboards for some of the world's largest and most demanding companies and has built Dashboards around virtually any KPI with RPS TEK SOL’s development accelerators.
          </div>
        </div>
        </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5">
      <div class="a-box">
          <div class="img-container">
            <div class="img-inner">
              <div class="inner-skew">
                <img src={imgg5} alt="software Section" style={{height:"170px",marginTop:"22px",marginLeft:"-10px"}}/>
              </div>
            </div>
          </div>
          <div class="text-container">
            <h3>Java</h3>
            <div >
            Signin Soft Provides Java Solutions Companies of all sizes and domains worldwide. We are engaged in providing Pre-implementation Consulting, corporate training in java programming Implementation, java training.
          </div>
        </div>
        </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5">
      <div class="a-box">
          <div class="img-container">
            <div class="img-inner">
              <div class="inner-skew">
                <img src={imgg6} alt="software Section" style={{height:"170px",marginTop:"22px",marginLeft:"-10px"}}/>
              </div>
            </div>
          </div>
          <div class="text-container">
            <h3>Tableau - Business Intelligence And Analytics</h3>
            <div >
            Tableau’s rapid-fire business intelligence software lets everyone in your organization analyse and understand their data far faster than any other solution and at a fraction of their costs and resources.
          </div>
        </div>
        </div>
  </div>
  </div>
  </div>
<hr/>
<br/>


    
   
<Footer/>
  
    </div>
  )
}

export default SoftwareServices