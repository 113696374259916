import React from 'react'
import video1 from '../../src/assets/images/signin .mp4'


const Section = () => {
  
  return (

<div>
        <div className="section main-banner" id="top" data-section="section1">
         <video  controls autoplay="autoplay" muted loop id="bg-video">
            <source src={video1} type="video/mp4" />
         </video>
        </div>

    </div>
  )
}

export default Section