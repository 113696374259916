import React ,{useEffect}from 'react'

const Contact = () => {
  
  return (
    <div>
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">

          <div className="text-center">
            <h1 className="who mt-3"  alt="Signin Soft Contact">Contact</h1>
            <h3 className="ser-head">Get in Touch</h3>
            </div>

         
          <div className="row mt-5" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-6">
              <div className="info-box mb-4">
                <div className="icon-container mt-3">
                  <i className="fa fa-map-marker map-icon-3d fa-5x fa-3dicon "></i>
                </div>
                <h3>Our Address</h3>
               {/* <p><b>USA:</b>FaxianIT, Inc.371 Hoes Lane, Suite 200/E-4 Piscataway, NJ 08854, USA</p>
                <hr/>*/}
                <p><b>USA:</b>371 Hoes Lane, Suite 200/E3 Piscataway, NJ 08854</p>
               
                <hr/>
                <p><b>India:</b> Flat no.202, Sri Sai Goverdhan Kunj, SR Nagar, Hyderabad-500038</p>
<hr/>
                <p><b>Dubai,UAE: </b>HNS Business Center, Office 12, Emitech Building, AL Garhood</p>
              </div>
            </div>
            
            <div className="col-lg-3 col-md-6">
              <div className="info-box mb-4">
                <i className="fa fa-envelope  fa-animation-envelope mb-2 mt-3" ></i>
                <br/>
                <h3 >Email Us</h3>
                <p><b>USA:</b>info@signinsoft.net</p>
                <hr/>
                <p><b>India:</b>info@signinsoft.co.in</p>
                <hr/>
              </div>
            </div>
           
            <div className="col-lg-3 col-md-6">
              <div className="info-box mb-4">
                <div className="pulse" style={{marginBottom:28 ,marginTop:20}}>
                  <i className="fa fa-phone " ></i>
                </div>
                
                <h3>Call Us</h3>
                <p ><b>USA:</b>+1(302) 703-1113</p>
                <hr/>
                <p><b>India:</b>040 – 40023456</p>
                <hr/>
               <p>  <b>Dubai,UAE:</b> +971 554881986</p>
              </div>
            </div></div>
            
        

        </div>
      </section>

    </div>
  )
}



export default Contact