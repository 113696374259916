import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Schengen= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
      
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Schengen Visa" style={{marginTop:"20px"}}>Schengen Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
      <p>Schengen(Procesing Fee: 8000 INR)</p>
  <h5>Documents required</h5>
  <ul  className='p-3'>
  <li>Standard applications</li>
      <li>Original passport and old passports, if any</li>
      <li>Your passport should be valid for at least 3 months after departure, with at least two blank pages</li>
      <li>Return air tickets</li>
      <li>The visa application form, duly filled and signed</li>
      <li>Recent passport-size color photograph taken against a white background with matte-finish, size (35mm x 45mm with 80% face close up)</li>
      <li>Bank statements for the last 6 months or an original 'official affidavit of support’, duly attested and signed by a bank official</li>
      <li>Purpose of the visit and duration of travel mentioned in a letter</li>
      <li>Income tax returns for the past 3 years</li>
      <li>Appointment letter and a reference letter from your employer if you are employed</li>
      <li>Income Tax Returns of the last 3 years</li>
      <li>Marriage Certificate - If the passport carries the maiden name</li>
      <li>Travel insurance</li>
      </ul>
      <h5>Additional documents</h5>
<h5>If the applicant is employed:</h5>
<ul  className='p-3'>
<li> Salary Slip, etc</li>
</ul>

<h5>If the applicant is a company owner or self-employed</h5>
<ul  className='p-3'>
<li> Certificate of registration of the company</li>
<li>Covering letter on letter head</li>
</ul>
    
<h5>If the applicant is Student:</h5>
<ul  className='p-3'>
<li>Original NOC letter from school / college with ID card copy</li>
</ul>
 
 
  </div>
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Schengen