import React from 'react'
import Footer from './footer'
import Header from './header'
import Topnav from './Topnav'
import image from "../src/assets/images/cyber_banner_image.svg"
import image1 from "../src/assets/images/slide1.png"
import image2 from "../src/assets/images/slide2.png"
import image3 from "../src/assets/images/slide3.png"
import image4 from "../src/assets/images/slide4.png"
import image5 from "../src/assets/images/slide5.png"



const Training = () => {
  return (
    <div>
        <Topnav/>
       <Header/>
       
      
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 style={{marginTop:"20px"}}>Training</h1>
    <h2 className="banner-text">Signin Soft Inc was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
       <div className="container mt-5">
    <h3 className="ser-head">What Is Digital Marketing?</h3>
        <p style={{textAlign:"justify"}}>Digital Marketing can be defined as a unique marketing strategy that is implemented in digital platforms through Internet Medium to reach the target audience. When compared to traditional marketing, search analytics gives you an extra edge in Digital Marketing. Analytics empowers the business to analyze the success in their business strategies and provides the required data to modify the strategies to suit the market requirements and improve ROI.

            With our Digital Marketing Training, re-discover your creative instinct to design significant marketing strategies to promote a product/service related to any organization from any business sector.</p>
            <h3 className="ser-head">Course Description</h3>
            <p style={{textAlign:"justify"}}>Are You A Non-Technical Person Without Programming Skills, But Still Want To Pursue A Career In IT?
                Digital Marketing is the right course for you. Digital Marketing doesn’t need any programming skills and familiarity with the internet is sufficient to master the concepts to pursue a successful career A few months of dedicated efforts will ensure a rewarding career in the space of IT. The demand for Digital Marketing professionals is exceeding the supply and this void is creating ample job opportunities for non-technical graduates. We help you to crack a job by doing the handholding from training to placement</p>
   <p>Who Should Do?</p>
   <h5>Who Can Learn This Course?</h5>
   <ul className='p-3' >
    <li>Working Professionals</li>
    <li>Branding  / Sales / Marketing Professionals</li>
    <li>Students</li>
    <li>Entrepreneurs</li>
    <li>Startups</li>
    <li>Housewives</li>
   </ul>
   <h5>Opportunities For Digital Marketers</h5>
   <ul className='p-3'>
    <li>You can go for a Job / Freelancer/ Consultant</li>
    <li>You can start a website (Blog)</li>
    <li>You can start an App/Video Channel monetize</li>
    <li>You can start a Digital Marketing Agency</li>
    <li>Startups</li>
    <li>Housewives</li>
   </ul>
   <p style={{textAlign:"justify"}}>Signin Soft is a leading Digital Marketing certification training institute. Digital Marketing course will be delivered by highly experienced and certified trainers who are considered as one the best trainers in the industry and so we are considered to be one of the best Digital Marketing training institutes. The course is studded with myriad hands-on exercises on each topic that is covered in the training. Along with the training, we offer you 1-year e-learning access, 9 global certifications from Google, Facebook, YouTube, etc. and guaranteed placement assistance. Many of our past participants are working for various companies as digital marketing professionals</p>
<div>
    <h5>Things You Will Learn</h5>
<div className="container">
   

      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                Module 1 - Basics Of Digital Marketing
            </button>
          </h2>
          <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
               <h5> What is Domain and Types</h5>
<ul className='p-3'>
    <li>TLD, SLD, TLD Domain</li>
    <li>Domain Registration</li>
    <li>What is Web Hosting Server</li>
    <li>Windows Server</li>
    <li>Linux Server</li>
</ul>
<ul className='p-3'>
    <li>What is Digital Marketing?</li>
    <li>How is it different from Traditional Marketing?</li>
    <li>ROI between Digital and Traditional Marketing?</li>
    <li>Discussion on E-Commerce?</li>
    <li>Discussion on new trends and current scenario of the world?</li>
    <li>Setting up a vision, mission, and goals of Digital Marketing?</li>
</ul>
            </div>
          </div>
        </div>
       
      </div>

      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample2">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseOne">
                Module 2 - How To Create A Word Press Website?
            </button>
          </h2>
          <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample2">
            <div className="accordion-body">
                DNS Server<br/>
                What is Website?<br/>
                Dynamic, Static Websites<br/>
                Domain and Hosting registration<br/>
                Linking your domain to the Hosting<br/>
                Creating a WordPress website<br/>
                Managing a WordPress website.<br/>
                Free website creation<br/>
                Free webmails Creation up to 5<br/>
                Free database Creation<br/>
            </div>
          </div>
        </div>
       
      </div>

      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample3">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                Module 3 - Understanding Business Before Designing A Strategy
            </button>
          </h2>
          <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample3">
            <div className="accordion-body">
               <ul className='p-3'>
               <li> Understanding the organizational structure</li>
<li>Operations within the organization</li>
<li>Importance of understanding the business operations</li>
<li>Importance of Digital Marketing for a business</li>
               </ul>
            </div>
          </div>
        </div>
       
      </div>

      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample4">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                Module 4 - Algorithms And Backlinks
            </button>
          </h2>
          <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample4">
            <div className="accordion-body">
               <ul className='p-3'>
               <li> What is Link Building</li>
<li>One Way link building</li>
<li>Two Way or Reciprocal link</li>
<li>Link Wheel Building</li>
<li>White Hat Link Building</li>
<li>Black Hat Link Building</li>
<li>Grey Hat Link Building</li>
<li>Google Panda Algorithms</li>
<li>Google Penguin Algorithms</li>
<li>Google Page Rank Algorithms</li>
<li>Google Hummingbird Algorithms</li>
<li>Page layout Algorithms</li>
<li>Search Engine submission</li>
<li>Directory submissions</li>
<li>Dmoz Listing</li>
<li>Forum Creation / submissions</li>
<li>Blogs Creation, Posting and Commenting</li>
<li>Blog Post Message Creation and Submission</li>
<li>Articles Creation, Distribution and Commenting</li>
<li>Press Release Creation and Distribution</li>
<li>Newsletter posting and News Syndication</li>
<li>Forums Posting and Comments</li>
<li>Favicons Creation</li>
<li>B2B Posting</li>
<li>Driving traffic through dedicated Social Networking</li>
<li>Facebook Marketing (Fan Pages and Profiles)</li>
<li>Twitter Marketing</li>
<li>Social Bookmarking Strategy and Implementation</li>
<li>Podcasting: Audio and Video Optimization</li>
<li>Promoting Subsequent pages of the Website</li>
<li>Google Site Map Submission</li>
<li>Yahoo Site Map Submission</li>
<li>Social Bookmarking SBM</li>
<li>Social Networking</li>
<li>classNameified Ad Creation and Submission</li>
<li>Posting in other Content Network</li>
<li>Blog Creation and Commenting</li>
<li>RSS Feed Creation and Validation</li>
<li>Link Building</li>
<li>Link Popularity Monitoring and Reporting</li>
<li>Listed on Google/Yahoo Local Business Center</li>
<li>Wikki Submission.</li>
               </ul>
            </div>
          </div>
        </div>
       
      </div>
      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample44">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingFourf">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourf" aria-expanded="false" aria-controls="flush-collapseFourf">
                Module 5 - Advanced SEO Techniques and Analytics
            </button>
          </h2>
          <div id="flush-collapseFourf" className="accordion-collapse collapse" aria-labelledby="flush-headingFourf" data-bs-parent="#accordionFlushExample44">
            <div className="accordion-body">
            <ul className='p-3'>
                    <li>Schema Markup Implementation</li>
                    <li>Mobile Optimization</li>
                    <li>Accelerated Mobile Pages (AMP)</li>
                    <li>Local SEO Strategies</li>
                    <li>Rich Snippets and Structured Data</li>
                    <li>Voice Search Optimization</li>
                    <li>Video SEO</li>
                    <li>User Experience and Core Web Vitals</li>
                    <li>International SEO</li>
                    <li>Competitor Analysis and Benchmarking</li>
                    <li>Algorithm Updates and Recovery Strategies</li>
                    <li>Conversion Rate Optimization (CRO)</li>
                    <li>Analytics and Data Interpretation</li>
                    <li>Ranking Tracking and Reporting</li>
                    <li>Content Gap Analysis</li>
                    <li>User Engagement Signals</li>
                    <li>Link Reclamation</li>
                    <li>Technical SEO Audit</li>
                    <li>Local Listings Management</li>
                    <li>Brand Building and Online Reputation</li>
                    <li>Advanced Keyword Research</li>
                    <li>Link Building Outreach</li>
                </ul>
            </div>
          </div>
        </div>
       
      </div>
      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample5">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                Module 6 - Social Media Marketing
            </button>
          </h2>
          <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample5">
            <div className="accordion-body">
               <ul className='p-3'>
<li>Facebook Marketing (paid)</li>
<li>Twitter Marketing (paid)</li>
<li>LinkedIn Marketing (paid)</li>

               </ul>
            </div>
          </div>
        </div>
       
      </div>

      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample6">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingsix">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                Module 7 - Video Marketing
            </button>
          </h2>
          <div id="flush-collapsesix" className="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample6">
            <div className="accordion-body">
               <ul className='p-3'>
<li>YouTube Channel Creation</li>
<li>YouTube Video Optimization</li>
<li>Video SEO</li>
<li>Increase YouTube views, Subscribers</li>
<li>YouTube Ads</li>
<li>Dailymotion</li>
<li>Vimeo Videos</li>
<li>Metacafe</li>
<li>Google Videos</li>

               </ul>
            </div>
          </div>
        </div>
       
      </div>
      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample8">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingeight">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                Module 8 - Web Analytics
            </button>
          </h2>
          <div id="flush-collapseeight" className="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample8">
            <div className="accordion-body">
               <ul className='p-3'>
<li>Navigate Google Analytics with ease</li>
<li>Using Analytics as a part of SEO / SEM strategies</li>
<li>Analyzing website traffic and content reports</li>
<li>Setting up of goals, event and tracking campaigns</li>
<li>Google Analytics Certification Guidance</li>


               </ul>
            </div>
          </div>
        </div>
       
      </div>

      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample9">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingnine">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                Module 9 - Google Ads
            </button>
          </h2>
          <div id="flush-collapsenine" className="accordion-collapse collapse" aria-labelledby="flush-headingnine" data-bs-parent="#accordionFlushExample9">
            <div className="accordion-body">
               <ul className='p-3'>
<li>1. Campaigns Covered
    <ul>
        <li>Google PPC Campaigns</li>
    </ul>
</li>
<li>2. Overview of the Search Marketing Landscape
    <ul>
       <li> A review of the latest search marketing trends</li>
       <li>A look at the latest pay-per-click advertising options</li>
       <li>Comparing Google Ads with Facebook, LinkedIn and Twitter advertising</li>
       <li>Choosing between SEO and PPC</li>
       <li>What is Pay Per Click (PPC)?</li>
       <li>How does PPC work?</li>
       <li>Importance and Benefits of PPC</li>
    </ul>
</li>
<li>Google Ads Modules
    <ul className='p-3'>
        <li>MCC Ads</li>
        <li>Adwords Express</li>
        <li>Adwords Merchant Center</li>
        <li>Adwords Editor</li>
        <li>GOMC Challenge</li>
        <li>Creating an Online Video Campaign (YouTube Ads)</li>
        <li>Shopping Ads</li>
    </ul>
</li>
<li>Setting up a PPC Campaign
    <ul className='p-3'> 
        <li>Google Ads Account Structure</li>
        <li>Google Search Network</li>
        <li>Google Display Network</li>
        <li>Google Partner Websites</li>
        <li>Location / Language Targeting</li>
        <li>Bid Budget Management</li>
        <li>Accelerated / Standard Delivery</li>
        <li>CPC, CPM, CPA, CPV, CPD Methods</li>
        <li>Keyword types (Broad, Phrase, Exact and Negative)</li>
        <li>Ad Rank, Actual CPC, CTR</li>
        <li>Ad Extensions</li>
        <li>Schedule: Start date, end date, Ad scheduling</li>
        <li>Ad Delivery: Ad rotation, frequency capping</li>
        <li>Quality Score and its Importance</li>
    </ul>
</li>
<li>Display Network Targeting
    <ul className='p-3'>
        <li>Concept of CPM and Branding</li>
        <li>Landing Page Optimization</li>
        <li>Automatic / Manual Placements</li>
        <li>Interest and Groups Targeting</li>
        <li>Remarketing Targeting</li>
        <li>Age, Gender Targeting</li>
        <li>Topic-Based Targeting</li>
       
        
    </ul>
</li>
<li>Ad Formats and Ad Guidelines</li>
<ul className='p-3'>
    <li>Create Effective Ads</li>
    <li>Ad copywriting best practices Ad copy testing</li>
    <li>Text Ads and Guidelines</li>
    <li>Image Ad Formats and Guidelines</li>
    <li>Display Ad Builder Ads and Guidelines</li>
    <li>Video Ads Format
    </li>
</ul>
<li>Tools and Analysis</li>
<ul className='p-3'>
    <li>What is a Landing Page?</li>
    <li>Optimizing your Landing Pages</li>
    <li>Competitors Website Analysis
    </li>
    <li>Ad group Performance Reports</li>
    <li>Using ‘Calls to Action’</li>
    <li>Keywords Performance Reports</li>
    <li>Purpose of Conversions</li>
    <li>Create your Conversion Tracking Code</li>
    <li>Tracking the Conversions for Multiple Product</li>
    <li>Tracking the Google Analytics Code</li>
    <li>Change History Tool</li>
    <li>Display Planner</li>
    <li>Ad preview Diagnosis</li>
   
</ul>
<li>Google Ads Live Project</li>



               </ul>
            </div>
          </div>
        </div>
       
      </div>
</div>
</div>

<div class="slider ">
	<div class="slide-track">
		<div class="slide">
			<img src={image1} height="100" width="250" alt="logo" />
		</div>
   
		<div class="slide">
			<img src={image2} height="100" width="250" alt="logo"  />
		</div>
		<div class="slide">
			<img src={image3} height="100" width="250" alt="logo" />
		</div>
		<div class="slide">
			<img src={image4} height="100" width="250" alt="logo" />
		</div>
		<div class="slide">
			<img src={image5} height="100" width="250" alt="logo" />
		</div>
    <div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/11571488731.png" height="100" width="250" alt="logo" />
		</div>
   
		<div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/21571488737.png" height="100" width="250" alt="logo" />
		</div>
		<div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/31571488742.png" height="100" width="250" alt="logo" />
		</div>
		<div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/41571488750.png" height="100" width="250" alt="logo" />
		</div>
		<div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/51571488755.png" height="100" width="250" alt="logo" />
		</div><div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/11571488731.png" height="100" width="250" alt="logo" />
		</div>
   
		<div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/21571488737.png" height="100" width="250" alt="logo" />
		</div>
		<div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/31571488742.png" height="100" width="250" alt="logo" />
		</div>
		<div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/41571488750.png" height="100" width="250" alt="logo" />
		</div>
		<div class="slide">
			<img src="https://signinsoft.co.in/uploads/images/51571488755.png" height="100" width="250" alt="logo" />
		</div>
		
	</div>
</div>
<div className="container mt-5">
    <h5>FAQ's</h5>
    <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample11">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading11">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                What Is Digital Marketing?
            </button>
          </h2>
          <div id="flush-collapse11" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample11">
            <div className="accordion-body">
            <p>Digital Marketing or Internet Marketing is marketing which is done using the internet as a medium. There are many techniques involved in doing online marketing and we train all the modules viz. SEO, Google Ads, SMM, Email Marketing, ORM and Google Analytics.</p>

            </div>
          </div>
        </div>
       
      </div>

      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample12">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading12">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                Who Can Learn This Course?
            </button>
          </h2>
          <div id="flush-collapse12" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample12">
            <div className="accordion-body">
                <h5>Anyone who is tech-savvy can learn this Digital Marketing Course as it is not limited to any particular age group</h5>

                Content Writers<br/>
                Web designers / Web developers / UI, UX professionals<br/>
                Students / Housewives / Internet Addicts<br/>
                Marketing and Branding Professionals<br/>
                Start-up / Business Owners<br/>

            </div>
          </div>
        </div>
       
      </div>

      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample13">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading13">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                What Are The Pre-Requisites?
            </button>
          </h2>
          <div id="flush-collapse13" className="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample13">
            <div className="accordion-body">
                <h5>Anyone who is tech-savvy can learn this Digital Marketing Course as it is not limited to any particular age group</h5>

                Content Writers<br/>
                Web designers / Web developers / UI, UX professionals<br/>
                Students / Housewives / Internet Addicts<br/>
                Marketing and Branding Professionals<br/>
                Start-up / Business Owners<br/>

            </div>
          </div>
        </div>
       
      </div>

      <div className="accordion accordion-flush shadow-lg mt-3" id="accordionFlushExample14">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-heading14">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                What Certifications Are Involved In The Digital Marketing Course?
            </button>
          </h2>
          <div id="flush-collapse14" className="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample14">
            <div className="accordion-body">
                <h5>We provide you with 9 International certifications from Google, Facebook, YouTube, etc which are

                </h5>

               <ul className='p-3'>
                <li>6 Google Ads Certifications</li>
                <li>Google Analytics Certification</li>
                <li>Facebook Blueprint Certification</li>
                <li>Industry Recognized ExcelR Certificate</li>
               </ul>
            </div>
          </div>
        </div>
       
      </div>



</div>
<br/>
</div>
<Footer/>
    </div>
  )
}

export default Training