import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/visa.png"


const Switzerland= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Switzerland Visa" style={{marginTop:"20px"}}>Switzerland Visa</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>
      <div class="container mt-5">
  <p>Along with the original passport with a validity of minimum of three months, a minimum of two pages should be blank from both sides for the visa stamp.</p>
  <h5 class="mt-3">The following documents need to be attached:</h5>
<ul  className='p-3'>
  <li>Copy of visas obtained by the applicant previously.</li>
  <li>Copy of first and last page of the passport.</li>
<li>Copies of old passports showing travel history must be attached as well.</li>
<li>Passports issued beyond 10 years will not be accepted by the Embassy.</li>
<li>The embassy will not accept hand-written passports.</li>
</ul>
<h5 class="mt-3">If the applicant is married and traveling with a spouse, the following documents must be attached:</h5>
<ul  className='p-3'>
    <li>The name of the spouse should be endorsed on the passport or a copy and original marriage certificate issued by the Registrar of Marriages of the concerned state, which has to be dully legalised by the component State Home Ministry.</li>
    <li>Visa application form must be dully filled by the applicant, and signed on column 37 and main column.</li>
    <li>The Declaration and Authorization form also needs to be duly filled, signed and submitted along with the application.</li>
    <li>Please Note: The applicant’s mobile number should be mentioned in the visa form as the Embassy or the Consulate can contact the applicant at any point of time during the visa processing.</li>
    <li>The applicant has to submit two recent passport-size photographs with matte or semi-matte finish, 60%-80% face coverage, white background and without border (Size: 35mm x 45mm)</li>
    <li>Please note: The photograph should not be more than 3 months old, scanned/stapled and should not be used in any of the previous visas.</li>
    <li>He/she must submit a covering letter on business letter head with a clear mention of his/her name, designation, passport number, purpose and duration of visit in brief. The letter should be duly signed by authorized signatory with company stamp and addressed to – The Consulate General of Switzerland, Mumbai.</li>
</ul>
<h5 class="mt-3">
    Please note:
</h5>
<ul  className='p-3'>
    <li>The dates on the applicant’s hotel bookings should match the dates on air tickets and the maximum stay should be in Switzerland.</li>
    <li>Travel Insurance: Original medical insurance for the stay duration (minimum for 15 days) amounting to 30,000 Euros.</li>
    <li>As proof of the applicant’s financials, the following documents need to be submitted to the consulate:</li>
    <li>If the applicant is an employee of any firm:</li>
    <li>Personal ITR for last 2 years</li>
    <li>Last three months’ salary slip</li>
    <li>Company and Personal Bank Statement of the last 3 months</li>
    <li>Credit Card copy/Foreign Exchange endorsement/Traveler’s Cheque</li>
    
</ul>
<h5 class="mt-3">For first time travelers to Europe:</h5>
<ul  className='p-3'>
    <li>Personal interview</li>
    <li>ITR for 3 years</li>
    <li>Original Bank statement for the last 3 months with bank's seal and signature</li>
    <li>Business Correspondence</li>
    <li>Qualification and salary slips</li>
   
</ul>
<h5 class="mt-3">Please Note: </h5>
    <ul  className='p-3'>
        <li>Original Bank Statement for the last six months with the bank's seal & signature.</li>
        <li>ITR for last 3 years</li>
        <li>Proof of Occupation</li>
    </ul>

<h5 class="mt-3">If the applicant is Self Employed:</h5>
<ul  className='p-3'>
    <li>Company Registration Certificate / Certificate of Incorporation / Proof of Proprietorship / Partnership Deed</li>
    <li>Brief Company Profile</li>
    <li>Articles of Memorandum (Incase the applicant is MD/Director)</li>
</ul>
<h5 class="mt-3">
    If the applicant is Employed:
</h5>
<ul  className='p-3'>
    <li>No Objection Certificate from employer</li>
</ul>
<h5 class="mt-3">
    If the applicant is Student:
</h5>
<ul  className='p-3'>
    <li>Leave Sanction Letter, School ID Card and NOC from school mentioning the dates of the trip</li>
</ul>
<p>If the applicant is under 18, these documents also need to be submitted:
    If the applicant is traveling alone, an authorization letter signed by both parents with their passports or a notarized affidavit signed by both parents is required.</p>
</div> 
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default Switzerland