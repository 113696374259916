import React from 'react'
import { Route,Routes } from "react-router-dom";
import Home from '../Home/home';
import Careerjobs from '../careerjobs';
import Training from '../Training';
import Visa from '../services/visa';
import TouristPackage from '../services/TouristPackage';
import FlightTickets from '../services/FlightTickets';
import Australia from '../services/Australia';
import Dubai from '../services/dubai';
import Thailand from '../services/thailand';
import Singapore from '../services/singapoor';
import Malaysia from '../services/malaysia';
import Srilanka from '../services/srilanka';
import Cambodia from '../services/cambodia';
import Japan from '../services/japan';
import Usa from '../services/usa';
import SoftwareServices from '../services/SoftwareServices';
import WebDevelopment from '../services/WebDevelopment';
import DigitalMarketing from '../services/DigitalMarketing';
import Switzerland from '../services/switzerland';
import China from '../services/china';
import Schengen from '../services/schengen';
import Germany from '../services/germany';
import Italy from '../services/italy';
import About from '../Home/about';

const Signinroutes = () => {
    return (
      <div>
  
  <Routes>
        <Route path="/" element= {<Home/>}/>
        <Route path="/Home" element= {<Home/>}/>
        <Route path="/Careerjobs" element= {<Careerjobs/>}/>
        <Route path="/Training" element= {<Training/>}/>
        <Route path="/Visa" element= {<Visa/>}/>
        <Route path="/TouristPackage" element= {<TouristPackage/>}/>
        <Route path="/FlightTickets" element= {<FlightTickets/>}/>
        <Route path="/Australia" element= {<Australia/>}/>
        <Route path="/Dubai" element= {<Dubai/>}/>
        <Route path="/Thailand" element= {<Thailand/>}/>
        <Route path="/Singapore" element= {<Singapore/>}/>
        <Route path="/Malaysia" element= {<Malaysia/>}/>
        <Route path="/Srilanka" element= {<Srilanka/>}/>
        <Route path="/Cambodia" element= {<Cambodia/>}/>
        <Route path="/Japan" element= {<Japan/>}/>
        <Route path="/USA" element= {<Usa/>}/>
        <Route path="/SoftwareServices" element= {<SoftwareServices/>}/>
        <Route path="/WebDevelopment" element= {<WebDevelopment/>}/>
        <Route path="/DigitalMarketing" element= {<DigitalMarketing/>}/>
        <Route path="/Switzerland" element= {<Switzerland/>}/>
        <Route path="/China" element= {<China/>}/>
        <Route path="/Schengen" element= {<Schengen/>}/>
        <Route path="/Germany" element= {<Germany/>}/>
        <Route path="/Italy" element= {<Italy/>}/>

  </Routes>
      </div>
    )
  }
  
  export default Signinroutes