import React from "react";
import './App.css';
import Signinroutes from "./Routes/signinroutes";



const App = () => {
  return (
   
 <Signinroutes/>
      
    
  );
};

export default App;
