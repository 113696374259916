import React from 'react'
import Footer from '../footer'
import Header from '../header'
import Topnav from '../Topnav'
import image from "../../src/assets/images/Digitalmarketing.png"
import image2 from "../../src/assets/images/Digital.png"



const DigitalMarketing= () => {

   
  return (
    <div>
        <Topnav/>
       <Header/>
       
     
<section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1 alt="Digital Marketing" style={{marginTop:"20px"}}>Digital Marketing</h1>
    <h2 className="banner-text">Signin Soft was established in July 2015 with the mission of aiding entrepreneurs, as well as small, medium, and large-scale business owners.</h2>
    
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/></div>
  </div>
  </div>
</section>

       <div className="container mt-5">

      <div className="row">
        <div className="col-sm-12 col-md-4 col-xl-4 col-lg-4">
            <img src={image2} alt="DigitalMarketing" className="img-fluid"/>

        </div>
        <div className="col-sm-12 col-md-8 col-xl-8 col-lg-8 mt-5">
          <h3>SERVICES AT A GLANCE</h3>
          <ul className='p-3'>
          <li>Online Marketing</li>
          <li>Digital Media Planning</li>
          <li>Search Engine Marketing</li>
          <li>Search Engine Optimization</li>
          <li>Social Media Optimization</li>
          <li>Social Media Marketing</li>
    
  </ul>
        </div>
      </div>
    </div>
<hr/>
<br/>


    
   
<Footer/>
    </div>
  )
}

export default DigitalMarketing